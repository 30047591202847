/** @format */

import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/title';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: 600,
    position: 'relative',
    color: theme.palette.text.secondary,
    '& iframe': {
      border: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      padding: 10,
    },
  },
  cam: {
    width: '100%',
  },
}));

export default function Production() {
  const [boxes, setBoxes] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(Number(e.target.value));
  };

  useEffect(() => {
    getBoxes();
  }, []);

  const getBoxes = () => {
    let url = `/api/shipping/boxes`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        //open dialog
        // console.log(resp);
        setCount(resp.length);
        setBoxes(resp);
      })
      .catch(e => {});
  };

  const editItem = box => () => {
    console.log(box);
  };

  const deleteItem = id => () => {
    console.log(id);
  };

  return (
    <React.Fragment>
      <Title>Boxes</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Dim</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Bulk</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {boxes.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.name || ''}</TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>{`${row.l} x ${row.w} x ${row.h}`}</TableCell>
              <TableCell>{row.qty}</TableCell>
              <TableCell>
                <Checkbox
                  checked={row.bulk}
                  disabled
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              </TableCell>
              <TableCell align="right">
                <ButtonGroup
                  color="primary"
                  size="small"
                  aria-label="large outlined secondary button group">
                  <Button
                    color="primary"
                    title="reprint"
                    className={classes.button}
                    aria-label="download gcode"
                    onClick={editItem(row)}>
                    <Icon>create</Icon>
                  </Button>
                  <Button
                    color="primary"
                    title="Cancel Line"
                    className={classes.button}
                    aria-label="Cancel Line"
                    onClick={deleteItem(row.id)}>
                    <Icon>delete</Icon>
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </React.Fragment>
  );
}
