/** @format */

import React, {useState, useEffect, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/title';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import TextField from '@material-ui/core/TextField';
import RightIcon from '@material-ui/icons/ChevronRight';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import {updateURLParams} from '../../utils/common';

const today = new Date().toISOString().substring(0, 10);
let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow = tomorrow.toISOString().substring(0, 10);

export default function Report({history, addAlert}) {
  // const classes = useStyles();
  const location = useLocation();
  const {qs} = location;
  const updateUrl = updateURLParams(location, history);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  // const [startDate, setStartDate] = useState(today);
  // const [endDate, setEndDate] = useState(tomorrow);
  const [loading, setLoading] = useState(true);
  const [ins, setIns] = useState(0);
  const [outs, setOuts] = useState(0);
  const reportRef = useRef();

  const handleChangePage = (e, page) => {
    updateUrl({page});
  };

  const handleChangeRowsPerPage = e => {
    updateUrl({limit: e.target.value});
  };

  useEffect(() => {
    if (qs.startDate) {
      getData();
    } else {
      let defaults = {
        limit: 50,
        page: 0,
        startDate: today,
        endDate: tomorrow,
      };
      updateUrl(defaults);
    }
    // eslint-disable-next-line
  }, [location]);

  const getData = async () => {
    let query = new URLSearchParams();
    query.set('options.material', qs.material);
    if (qs.diameter) {
      query.set('options.diameter', qs.diameter);
      query.set('options.amount', qs.amount);
    }
    let items = await getItemDetails(query, qs.startDate, qs.endDate);
    let itemIds = items.map(i => i.id);
    let transactions = await getTransactions(
      query,
      qs.startDate,
      qs.endDate,
      itemIds
    );
    let data = aggregateData(items, transactions);

    let i = 0;
    let o = 0;
    data.forEach(t => {
      i += t.in;
      o += t.out;
    });
    setIns(i);
    setOuts(o);
    setItems(data);
    setCount(data.length);
    setLoading(false);
  };

  const aggregateData = (items, transactions) => {
    let groupData = {};
    transactions.forEach(t => {
      if (!groupData[t.itemId]) {
        groupData[t.itemId] = [];
      }
      groupData[t.itemId].push(t);
    });
    let resp = items.filter(item => {
      if (groupData[item.id]) {
        item.transactions = groupData[item.id];
        item.open = false;
        let qtyout = 0;
        let qtyin = 0;
        item.transactions.forEach(t => {
          if (t.qty < 0) {
            qtyout += t.qty;
          } else {
            qtyin += t.qty;
          }
        });
        item.out = qtyout;
        item.in = qtyin;
        return true;
      } else {
        return false;
      }
    });
    return resp;
  };

  const getTransactions = (query, st, et, itemIds) => {
    let url = `/api/inventory_transactions?skip=0&limit=0&all=true&startDate=${st}&endDate=${et}&itemIds=${itemIds}&q=${encodeURIComponent(
      query
    )}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(async resp => {
        return resp.resp;
      })
      .catch(e => {});
  };

  const getItemDetails = (query, st, et) => {
    let itemsUrl = `/api/items?skip=${
      Number(qs.page) * Number(qs.limit)
    }&limit=${Number(
      qs.limit
    )}&all=true&startDate=${st}&endDate=${et}&q=${encodeURIComponent(query)}`;
    return fetch(itemsUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        return resp;
      })
      .catch(e => {});
  };

  const getItems = e => {
    let opts = {};
    opts[e.target.dataset.options] = e.target.value;
    if (opts.material === 'raw' || opts.material === 'parts') {
      opts.diameter = '';
      opts.amount = '';
    }
    updateUrl(opts);
  };

  const toggleTransactions = idx => () => {
    let tmp = [...items];
    tmp[idx].open = !tmp[idx].open;
    setItems(tmp);
  };

  return (
    <React.Fragment>
      <Title>Items</Title>
      <div
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          padding: '0px 10px 20px 10px',
          width: '100%',
        }}>
        <div style={{flex: '0 0 50%', textAlign: 'left'}}>
          <FormControl>
            <TextField
              id="date"
              label="Start Date"
              type="date"
              value={qs.startDate}
              onChange={e => {
                updateUrl({startDate: e.target.value});
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FormControl>
            <TextField
              id="date"
              label="End Date"
              type="date"
              value={qs.endDate}
              onChange={e => {
                updateUrl({endDate: e.target.value});
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </div>

        <div style={{flex: '0 0 50%', textAlign: 'right'}}>
          <FormControl>
            <ReactToPrint
              trigger={() => (
                <IconButton aria-label="print">
                  <PrintIcon />
                </IconButton>
              )}
              content={() => reportRef.current}
            />
          </FormControl>
        </div>
        <div style={{flex: '0 0 50%', textAlign: 'left'}}>
          <FormControl component="fieldset" style={{marginRight: '35px'}}>
            <FormLabel component="legend">Type</FormLabel>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="PLA"
              value={qs.material}
              data-options="material"
              onChange={getItems}>
              <FormControlLabel
                value="raw"
                control={
                  <Radio
                    color="primary"
                    inputProps={{'data-options': 'material'}}
                  />
                }
                label="Raw"
                // labelPlacement="top"
              />
              <FormControlLabel
                value="parts"
                control={
                  <Radio
                    color="primary"
                    inputProps={{'data-options': 'material'}}
                  />
                }
                label="Parts"
                // labelPlacement="top"
              />
              <FormControlLabel
                value="PLA"
                control={
                  <Radio
                    color="primary"
                    inputProps={{'data-options': 'material'}}
                  />
                }
                label="PLA"
                // labelPlacement="top"
              />
              <FormControlLabel
                value="PLA Ltd"
                control={
                  <Radio
                    color="primary"
                    inputProps={{'data-options': 'material'}}
                  />
                }
                label="PLA Ltd"
                // labelPlacement="top"
              />
              <FormControlLabel
                value="PLAPLUS"
                control={
                  <Radio
                    color="primary"
                    inputProps={{'data-options': 'material'}}
                  />
                }
                label="Forge"
                // labelPlacement="top"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div style={{display: 'flex'}}>
          {qs.material !== 'raw' && qs.material !== 'parts' && (
            <FormControl component="fieldset">
              <FormLabel component="legend">Diameter</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={qs.diameter}
                data-options="diameter"
                onChange={getItems}>
                <FormControlLabel
                  value="1.75mm"
                  control={
                    <Radio
                      color="primary"
                      inputProps={{'data-options': 'diameter'}}
                    />
                  }
                  label="1.75mm"
                  // labelPlacement="top"
                />
                <FormControlLabel
                  value="2.85mm"
                  control={
                    <Radio
                      color="primary"
                      inputProps={{'data-options': 'diameter'}}
                    />
                  }
                  label="2.85mm"
                  // labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          )}
          {qs.material !== 'raw' && qs.material !== 'parts' && (
            <FormControl component="fieldset">
              <FormLabel component="legend">Amount</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={qs.amount}
                data-options="amount"
                onChange={getItems}>
                <FormControlLabel
                  value="1kg"
                  control={
                    <Radio
                      color="primary"
                      inputProps={{'data-options': 'amount'}}
                    />
                  }
                  label="1kg"
                  // labelPlacement="top"
                />
                <FormControlLabel
                  value="200g"
                  control={
                    <Radio
                      color="primary"
                      inputProps={{'data-options': 'amount'}}
                    />
                  }
                  label="200g"
                  // labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          )}
        </div>
      </div>
      <Table size="small" ref={reportRef}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>ItemId</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>qoh</TableCell>
            <TableCell>In</TableCell>
            <TableCell align="right">Out</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {items.map((row, idx) => (
            <React.Fragment key={row.id}>
              <TableRow key={row.id}>
                <TableCell>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={toggleTransactions(idx)}>
                    {row.open ? <DownIcon /> : <RightIcon />}
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Link to={`/item/${row.id}`}>{row.id}</Link>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  {row.qoh.toFixed(2).replace(/\.00$/, '')}{' '}
                  {row.uom !== 'each' && row.uom}
                </TableCell>
                <TableCell>
                  {row.in.toFixed(2).replace(/\.00$/, '')}{' '}
                  {row.uom !== 'each' && row.uom}
                </TableCell>
                <TableCell align="right">
                  {row.out.toFixed(2).replace(/\.00$/, '')}{' '}
                  {row.uom !== 'each' && row.uom}
                </TableCell>
                {/* <TableCell>${(row.price / 100).toFixed(2)}</TableCell> */}
              </TableRow>
              {row.open && (
                <TableRow key={row.id + '_transactions'}>
                  <TableCell colSpan={6}>
                    <Table size="small">
                      <TableHead style={{backgroundColor: 'lightgray'}}>
                        <TableRow>
                          <TableCell>Transaction Id</TableCell>
                          <TableCell>qty</TableCell>
                          <TableCell>UOM</TableCell>
                          <TableCell>Reason</TableCell>
                          <TableCell>User</TableCell>
                          <TableCell align="right">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.transactions.map(t => (
                          <TableRow key={t.id}>
                            <TableCell>{t.id}</TableCell>
                            <TableCell>
                              {t.qty.toFixed(2).replace(/\.00$/, '')}
                            </TableCell>
                            <TableCell>{t.UOM}</TableCell>
                            <TableCell>{t.reason}</TableCell>
                            <TableCell>{t._system.createdBy}</TableCell>
                            <TableCell align="right">
                              {new Date(t._system.created).toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
          <TableRow style={{backgroundColor: 'lightgrey'}}>
            <TableCell>Totals:</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{ins}</TableCell>
            <TableCell align="right">{outs}</TableCell>
            {/* <TableCell>${(row.price / 100).toFixed(2)}</TableCell> */}
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={Number(qs.limit) || 0}
              page={Number(qs.page) || 0}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </React.Fragment>
  );
}
