/** @format */

import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import Fab from '@material-ui/core/Fab';
import {TextField, Paper} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import io from 'socket.io-client';

const wsUrl = window.location.origin
  .replace('https', 'wss')
  .replace('http', 'ws');
const opts = {
  reconnectionDelayMax: 10000,
  path: '/api/chat/sockets/',
  reconnection: false,
  // transports:['websocket'],
};
const socket = io(wsUrl, opts);

socket.on('connect', data => {
  // console.log('connected...');
});
socket.on('disconnect', reason => {
  // console.log('dissconnect: ', reason);
});
socket.on('connect_error', err => {
  // console.log('err: ', err);
});

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 10,
    right: 10,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    height: 80,
    padding: 10,
  },
  messageArea: {
    height: '70%',
    overflow: 'auto',
  },
}));

export default function Chat() {
  const classes = useStyles();
  const [convos, setConvos] = useState([]);
  const [count, setCount] = useState(0);
  const [current, setCurrent] = useState({});
  const [currentMessages, setCurrentMessages] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [msg, setMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEnd = useRef(null);
  const handleChangePage = (e, page) => {
    setPage(page + 1);
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(Number(e.target.value));
  };

  const scrollToBottom = () => {
    messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight;
  };

  const handleCurrentChange = async change => {
    if (change && change.id) {
      await getMessages(change.id);
      setCurrent(change);
      socket.emit('joinRoom', {roomId: change.id});
      socket.on(change.id, addMessage);
    }
    scrollToBottom();
  };

  const getMessages = async roomId => {
    const SORT = -1;
    const LIMIT = 20;
    const SKIP = currentMessages.length;

    // if (userRoom.msgCount <= SKIP) {
    //   return;
    // }
    console.log(current);
    if (!isLoading) {
      setIsLoading(true);
      const resp = await fetch(
        `/api/chat/${roomId}?sort=${SORT}&limit=${LIMIT}&skip=${SKIP}`
      );
      const newMessages = await resp.json();
      if (newMessages[0]) {
        const messages = [...newMessages[0].msgs, ...currentMessages];
        setCurrentMessages(messages);
      }
      setIsLoading(false);
    }
  };

  const addMessage = line => {
    let tmp = [...currentMessages];
    tmp.push(line);
    setCurrentMessages(tmp);
  };

  const sendMessage = e => {
    socket.emit('sendMsg', {msg}, resp => {
      setMsg('');
      addMessage(resp);
    });
  };

  useEffect(() => {
    let url = `/api/chat`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        //open dialog
        let userIds = new Set();
        resp.forEach(r => {
          userIds.add(r._system.createdBy);
        });
        getUsers(Array.from(userIds), resp);
      })
      .catch(e => {});
  }, [page, rowsPerPage]);

  const getUsers = (ids, data) => {
    let url = `/api/users/bulk?userIds=${ids}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        //open dialog
        let userMap = {};
        resp.forEach(u => {
          userMap[u.userId] = u;
        });

        let newConvos = data.map(c => {
          c.user = userMap[c._system.createdBy];
          return c;
        });
        setConvos(newConvos);
        setCurrent(newConvos[0]);
        setCount(newConvos.length);
      })
      .catch(e => {});
  };

  const loadMore = async e => {
    if (e.target.scrollTop === 0) {
      const currentHeight = e.target.scrollHeight;
      await getMessages(current.id);
      const newHeight = messagesEnd.current.scrollHeight;
      const scrollOffset = newHeight - currentHeight;
      messagesEnd.current.scrollTop = scrollOffset;
    }
  };

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow></TableRow>
        </TableHead>
        <TableBody style={{textAlign: 'left'}}>
          <TableRow>
            <td></td>
            <td
              rowSpan="10"
              width="50%"
              style={{overflowY: 'scroll', height: '60vh', padding: 10}}>
              <List
                className={classes.messageArea}
                ref={messagesEnd}
                onScroll={loadMore}>
                {current &&
                  currentMessages &&
                  currentMessages.map((msg, i) => {
                    let side =
                      msg.userId === current._system.createdBy
                        ? 'left'
                        : 'right';
                    return (
                      <ListItem key={i}>
                        <Grid container>
                          <Grid item xs={12}>
                            <ListItemText
                              align={side}
                              primary={msg.msg}></ListItemText>
                          </Grid>
                          <Grid item xs={12}>
                            <ListItemText
                              align={side}
                              secondary={prettyDate(msg.ts)}></ListItemText>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
              </List>
              <Divider />
              <Grid container style={{padding: '20px'}}>
                <Grid item xs={11}>
                  <TextField
                    id="outlined-basic-email"
                    label="Type Something"
                    value={msg}
                    onChange={e => {
                      setMsg(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid align="right">
                  <Fab color="primary" aria-label="add" onClick={sendMessage}>
                    <SendIcon />
                  </Fab>
                </Grid>
              </Grid>
            </td>
          </TableRow>
          {convos.map((row, i) => (
            <TableRow key={i}>
              <td>
                <Paper
                  className={classes.paper}
                  onClick={() => {
                    handleCurrentChange(row);
                  }}>
                  <Avatar
                    alt="Remy Sharp"
                    src={row.user ? row.user.avatar : null}>
                    {row._system.createdBy.substr(0, 1)}
                  </Avatar>
                  {new Date(row._system.modified).toLocaleString()}
                </Paper>
              </td>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              colSpan={6}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}

const prettyDate = time => {
  var date = new Date(time),
    diff = (new Date().getTime() - date.getTime()) / 1000,
    day_diff = Math.floor(diff / 86400);

  if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) return;

  return (
    (day_diff === 0 &&
      ((diff < 60 && 'just now') ||
        (diff < 120 && '1 minute ago') ||
        (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
        (diff < 7200 && '1 hour ago') ||
        (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
    (day_diff === 1 && 'Yesterday') ||
    (day_diff < 7 && day_diff + ' days ago') ||
    (day_diff < 31 && Math.ceil(day_diff / 7) + ' weeks ago')
  );
};
