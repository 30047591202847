/** @format */

import React, {useState, useEffect} from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/title';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import UIfx from 'uifx';
import newSale from '../../sounds/confirmation_004.mp3';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddOrder from './addOrder';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BusinessIcon from '@material-ui/icons/Business';
import AdjustIcon from '@material-ui/icons/Adjust';
import SearchIcon from '@material-ui/icons/Search';
import ShipIcon from '@material-ui/icons/LocalShipping';
import BagIcon from '@material-ui/icons/WorkOutline';
import GroundIcon from '@material-ui/icons/LocalShipping';
import Tooltip from '@material-ui/core/Tooltip';
import InputBase from '@material-ui/core/InputBase';
import {makeStyles} from '@material-ui/core/styles';
import {updateURLParams} from '../../utils/common';
import {Button} from '@material-ui/core';
import Growl from '../../components/growl';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  searchContainer: {
    display: 'flex',
    alignContent: 'flex-end',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#eee',
    '&:hover': {
      backgroundColor: '#efefef',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Orders(props) {
  const classes = useStyles();
  let location = useLocation();
  const history = useHistory();
  const updateUrl = updateURLParams(location, history);
  const {
    socket,
    addAlert,
    updateOpenOrders,
    zebraPrinter,
    updateStats = () => {},
  } = props;
  const bell = new UIfx(newSale);
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [openItem, setOpenItem] = useState(false);
  const [search, setSearch] = useState('');
  const [growl, setGrowl] = useState({type: 'success', msg: '', show: false});

  const handleChangePage = (e, page) => {
    updateUrl({page});
  };

  const handleChangeRowsPerPage = e => {
    updateUrl({limit: e.target.value});
  };

  const openAddItem = () => {
    setOpenItem(true);
  };

  const handleItemClose = () => {
    setOpenItem(false);
  };

  useEffect(() => {
    socket.emit('initComplete');
    socket.on('newOrder', newOrder);
    socket.on('orderUpdate', orderUpdate);
    document.addEventListener('visibilitychange', activeWindow);
    return unload;
    // eslint-disable-next-line
  }, []);

  const activeWindow = () => {
    if (document.visibilityState === 'visible') {
      getOrders();
    }
  };

  const unload = () => {
    socket.off('newOrder', newOrder);
    socket.off('orderUpdate', orderUpdate);
    document.removeEventListener('visibilitychange', activeWindow);
  };

  const newOrder = () => {
    getOrders();
    bell.play();
    updateStats();
  };

  const orderUpdate = () => {
    getOrders();
  };

  useEffect(() => {
    if (location.search) {
      getOrders();
    } else {
      let defaults = {
        limit: 50,
        page: 0,
        openOrders: true,
      };
      updateUrl(defaults);
    }
    //eslint-disable-next-line
  }, [location]);

  const getOrders = () => {
    let {qs} = location;
    let url = localStorage.getItem('url') || '';
    let sort = '';
    if (qs.limit) {
      let statuses = 'all';

      if (qs.openOrders === 'true') {
        statuses = 'received,shipped,in progress,ready for pickup';
        sort = '&sort=status&dir=1';
      }

      if (qs.customerService === 'true') {
        statuses += ',customer service';
      }

      if (qs.subscriptions === 'true') {
        statuses = 'subscription';
      }

      url = `/api/sales/?skip=${qs.page * qs.limit}&limit=${
        qs.limit
      }&all=true&status=${statuses}${sort}`;
      if (qs.search) {
        url += `&search=${qs.search}`;
      }

      localStorage.setItem('url', url);
    }

    if (!url) {
      return;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        if (resp && resp.orders) {
          setCount(resp.count);
          setOrders(resp.orders);
          updateOpenOrders(resp.openOrders || 0);
        }
      });
  };

  const handleChange = event => {
    updateUrl({openOrders: event.target.checked});
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      updateUrl({search});
    }
  };

  const getRowColor = (status, row) => {
    let color = 'none';
    if (row.shipping.serviceCode === 0) {
      color = 'lightblue';
    } else if (row.shipping.serviceCode !== 1) {
      color = '#f1ff50';
    }

    switch (status) {
      case 'shipped':
        color = 'lightgrey';
        break;
      case 'delivered':
        color = '#7393B3';
        break;
      case 'ready for pickup':
        color = 'lightgreen';
        break;
      case 'customer service':
        color = '#ff5632';
        break;
      default:
        break;
    }
    return color;
  };

  const shipAll = async () => {
    const allow = window.confirm(
      'Are you sure you want to print all labels for the orders below?'
    );
    if (allow !== true) {
      return;
    }
    for (let order of orders) {
      const {id} = order;
      await shipPackage(id);
    }
    getOrders();
  };

  const shipPackage = id => {
    let url = `/api/shipping/order/${id}?saturday=false`;

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify([]),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(resp => {
        if (typeof resp === 'string') {
          try {
            resp = JSON.parse(resp);
          } catch (e) {
            console.error(e.message);
          }
        }
        if (resp[0] && resp[0].message) {
          setGrowl({type: 'error', msg: resp[0].message, show: true});
        } else {
          printShippingLabel(resp);
        }
        // open dialog
      })
      .catch(e => {
        setGrowl({type: 'error', msg: e.message, show: true});
      });
  };

  const printShippingLabel = labels => {
    labels.forEach(t => {
      if (t.encodedLabel) {
        zebraPrinter.send(
          atob(t.encodedLabel),
          () => {},
          e => {
            setGrowl({
              type: 'error',
              msg:
                'There was an issue sending label to the printer.  Is browserprint running?',
              show: true,
            });
          }
        );
      } else {
        zebraPrinter.convertAndSendFile(
          `/api/shipping/zpl?url=${t.label}&type=.zpl`,
          () => {},
          e => {
            setGrowl({
              type: 'error',
              msg:
                'There was an issue sending label to the printer.  Is browserprint running?',
              show: true,
            });
          }
        );
      }
    });
  };

  const getIconForShipping = shipping => {
    if (shipping.bulk) {
      return (
        <Tooltip title="Bulk Shipping">
          <BusinessIcon style={{color: 'rgba(0, 0, 0, 0.87)'}} />
        </Tooltip>
      );
    } else if (shipping.serviceCode === 0) {
      return (
        <Tooltip title="Local Pickup">
          <BagIcon style={{color: 'rgba(0, 0, 0, 0.87)'}} />
        </Tooltip>
      );
    } else if (shipping.serviceCode !== 1) {
      return (
        <Tooltip title="Ground Shipping">
          <GroundIcon style={{color: 'rgba(0, 0, 0, 0.87)'}} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Priority Shipping">
          <AdjustIcon style={{color: 'rgba(0, 0, 0, 0.87)'}} />
        </Tooltip>
      );
    }
  };

  return (
    <React.Fragment>
      <Title>Recent Orders</Title>
      <div className={classes.searchContainer}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={location.qs.openOrders === 'true'}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Open Orders"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={location.qs.customerService === 'true'}
                onChange={e => {
                  updateUrl({customerService: e.target.checked});
                }}
                name="customerService"
                color="primary"
              />
            }
            label="Customer Service"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={location.qs.subscriptions === 'true'}
                onChange={e => {
                  updateUrl({subscriptions: e.target.checked});
                }}
                name="subscriptions"
                color="primary"
              />
            }
            label="Subscriptions"
          />
        </div>
        <div style={{display: 'flex'}}>
          {location.qs.subscriptions === 'true' && (
            <div className={classes.search}>
              <Button
                onClick={shipAll}
                color="primary"
                startIcon={<ShipIcon />}>
                Ship All
              </Button>
            </div>
          )}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              inputProps={{'aria-label': 'search'}}
            />
          </div>
        </div>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="none" size="small"></TableCell>
            <TableCell>Order#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="right">Sale Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(row => (
            <TableRow
              key={row.id}
              style={{
                backgroundColor: getRowColor(row.status, row),
              }}>
              <TableCell
                style={{verticalAlign: 'bottom'}}
                align="center"
                padding="none"
                size="small">
                {getIconForShipping(row.shipping)}
              </TableCell>
              <TableCell>
                <Link to={`/order/${row.id}`}>{row.id}</Link>
              </TableCell>
              <TableCell>
                {row._system.createdBy.indexOf('cus_') !== 0 &&
                row._system.createdBy.indexOf('-') === -1 &&
                row.payment.refType.toLowerCase() !== 'promo' ? (
                  <Link to={`/user/${row._system.createdBy}`}>
                    {row.shipping.name ||
                      row.shipping.firstName + ' ' + row.shipping.lastName}
                  </Link>
                ) : (
                  row.shipping.name ||
                  row.shipping.firstName + ' ' + row.shipping.lastName
                )}
              </TableCell>
              <TableCell>
                {row.status.toTitleCase()}
                {row.status === 'shipped' &&
                  row.trackingInfo &&
                  row.trackingInfo[0].status && (
                    <span> ({row.trackingInfo[0].status.statusByLocale})</span>
                  )}
              </TableCell>
              <TableCell>
                {new Date(row._system.created).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                ${(row.payment.amount / 100).toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={Number(location.qs.limit) || 0}
              page={Number(location.qs.page) || 0}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Fab
        color="primary"
        onClick={openAddItem}
        aria-label="add"
        style={{position: 'fixed', right: 10, bottom: 10}}>
        <AddIcon />
      </Fab>
      {growl.show === true && (
        <Growl
          type={growl.type}
          message={growl.msg}
          closeHandler={() =>
            setGrowl({type: 'succcess', msg: '', show: false})
          }
        />
      )}
      <AddOrder
        openItem={openItem}
        addAlert={addAlert}
        handleItemClose={handleItemClose}
      />
    </React.Fragment>
  );
}
