/** @format */

import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import Chart from '../../components/chart';
import Deposits from '../../components/deposits';
import Orders from '../orders';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Dash = props => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [dailyTotals, setDailyTotals] = useState([]);
  useEffect(() => {
    getStats();
  }, []);

  const getStats = () => {
    let url = `/api/stats/`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        //open dialog
        // console.log(resp);
        resp.sort((a, b) => (a.day > b.day ? 1 : -1));
        setDailyTotals(resp);
      })
      .catch(e => {});
  };
  return (
    <>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={fixedHeightPaper}>
            <Chart dailyTotals={dailyTotals} title="Past 7 days" />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Deposits dailyTotals={dailyTotals} />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Orders updateStats={getStats} {...props} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Dash;
