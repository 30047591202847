/** @format */

import React, {useState} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/tabpanel';
import Line from './Line';

export default function Production(props) {
  const [value, setValue] = useState(0);

  const changeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={changeTab}
        indicatorColor="primary"
        textColor="primary"
        centered>
        <Tab label="Line 1" />
        <Tab label="Line 2" />
        <Tab label="Line 3" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Line line={1} {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Line line={2} {...props} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Line line={3} {...props} />
      </TabPanel>
    </>
  );
}
