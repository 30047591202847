/** @format */

import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/title';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import Button from '@material-ui/core/Button';

export default function Item({history, printerName, addAlert}) {
  // const classes = useStyles();
  const {id} = useParams();
  const [transactions, setTransactions] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [item, setItem] = useState(undefined);
  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(Number(e.target.value));
  };

  const voidTransaction = transactionId => () => {
    const v = window.confirm(
      'Are you sure you want to void out this transaction?'
    );
    if (v === true) {
      let url = `/api/inventory_transactions/${transactionId}/void`;
      fetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(function (res) {
          return res.json();
        })
        .then(resp => {
          if (resp.id) {
            setTransactions([resp, ...transactions]);
          } else {
          }
        })
        .catch(e => {});
    }
  };

  useEffect(() => {
    if (!id) {
      setItem(undefined);
    } else {
      let url = `/api/item/${id}`;
      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(function (res) {
          return res.json();
        })
        .then(resp => {
          setItem(resp);
        })
        .catch(e => {});
    }
  }, [id]);

  useEffect(() => {
    let url = `/api/inventory_transactions/?itemId=${id}&skip=${
      page * rowsPerPage
    }&limit=${rowsPerPage}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        setTransactions(resp.resp);
        setCount(resp.count);
      })
      .catch(e => {});
  }, [page, rowsPerPage, id]);

  return (
    <React.Fragment>
      <Title>Item</Title>
      <div
        style={{
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          display: 'flex',
          padding: '0px 10px 20px 10px',
        }}>
        {item && (
          <>
            {item.imgSrc && (
              <img alt={item.name} src={item.imgSrc[0] + '?w=75'} />
            )}
            <p style={{paddingLeft: 10, textAlign: 'left'}}>
              {item.name}
              <br />
              qoh: {item.qoh}
            </p>
          </>
        )}
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>TransactionId</TableCell>
            <TableCell>qty</TableCell>
            <TableCell>UOM</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>PO / Sale</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Date</TableCell>
            {/* <TableCell>Price</TableCell> */}
            <TableCell>User</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map(row => (
            <TableRow key={row.id}>
              <TableCell
                style={{
                  textDecoration:
                    row.transactionId && row.reason !== 'VOID'
                      ? 'line-through'
                      : 'none',
                }}>
                {row.id}
              </TableCell>
              <TableCell>{row.qty}</TableCell>
              <TableCell>{row.UOM}</TableCell>
              <TableCell>{row.reason}</TableCell>
              <TableCell>
                {row.saleId ? (
                  <Link to={`/order/${row.saleId}`}>{row.saleId}</Link>
                ) : (
                  row.PO
                )}
                {row.transactionId && (
                  <span
                    style={{
                      textDecoration:
                        row.transactionId && row.reason === 'VOID'
                          ? 'line-through'
                          : 'none',
                    }}>
                    {row.transactionId}
                  </span>
                )}
              </TableCell>
              {/* <TableCell>{row.PO || row.saleId}</TableCell> */}
              <TableCell>{row.location}</TableCell>
              <TableCell>
                {new Date(row._system.created).toLocaleString()}
              </TableCell>
              <TableCell>{row._system.createdBy}</TableCell>
              <TableCell>
                {!row.saleId && !row.transactionId && (
                  <Button
                    onClick={voidTransaction(row.id)}
                    size="small"
                    variant="outlined"
                    color="secondary">
                    Void
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </React.Fragment>
  );
}
