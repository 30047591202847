/** @format */

import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import {Checkbox} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
  items: {
    minWidth: 300,
  },
  root: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    minWidth: 800,
    alignItems: 'center',
  },
  textfield: {
    margin: 0,
  },
}));

export default function AddOrder({products, subItems, onChange}) {
  const classes = useStyles();
  const [items, setItems] = useState([{id: '', qty: 1}]);
  const [selectedBoxes, setSelectedBoxes] = useState({});
  const itemHandler = index => e => {
    let value = e.target.value;
    let name = e.target.name;
    let tmp = [...items];
    if (name === 'qty') {
      value = Number(value);
    }
    tmp[index][name] = value;
    setItems(tmp);
  };

  const subItemHandler = index => e => {
    let value = e.target.value;
    let id = e.target.id;
    let tmp = [...items];
    let tmp2 = {...selectedBoxes};
    if (!tmp[index].sides) {
      tmp[index].sides = [];
    }
    tmp2[index + id] = Number(value);
    tmp[index].sides = [];
    for (const key in tmp2) {
      let sideId = key.slice(index.toString().length);
      for (let x = 0; x < tmp2[key]; x++) {
        tmp[index].sides.push(sideId);
      }
    }
    setSelectedBoxes(tmp2);
    setItems(tmp);
  };

  useEffect(() => {
    onChange(items);
    // eslint-disable-next-line
  }, [items]);

  return (
    <form className={classes.form} noValidate>
      {items.map((item, index) => {
        return (
          <Grid
            key={index}
            container
            className={classes.root}
            alignItems="center"
            spacing={5}>
            <Grid item>
              <FormControl className={classes.items}>
                <InputLabel id="item-label">&nbsp;Item</InputLabel>
                <Select
                  labelId="item-label"
                  id="item"
                  label="Item"
                  variant="outlined"
                  name="id"
                  onChange={itemHandler(index)}
                  value={item.id}>
                  {products.map(product => {
                    return (
                      <MenuItem key={product.id} value={product.id}>
                        {product.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  // margin="normal"
                  name="qty"
                  value={item.qty}
                  label="Qty"
                  id="qty"
                  type="number"
                  className={classes.textfield}
                  onChange={itemHandler(index)}
                />
              </FormControl>
            </Grid>
            {items.length === index + 1 && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    let tmp = [...items];
                    tmp.push({id: '', qty: 1});
                    setItems(tmp);
                  }}
                  endIcon={<AddIcon />}>
                  Add
                </Button>
              </Grid>
            )}
            <Grid item>
              {subItems[item.id] &&
                subItems[item.id].map(s => {
                  return (
                    <FormControl className={classes.formControl} key={s.id}>
                      <TextField
                        variant="outlined"
                        // margin="normal"
                        name={s.tags.color}
                        value={selectedBoxes[index + s.id]}
                        label={s.tags.color}
                        id={s.id}
                        type="number"
                        className={classes.textfield}
                        onChange={subItemHandler(index, s.id)}
                      />
                    </FormControl>
                  );
                })}
            </Grid>
          </Grid>
        );
      })}
    </form>
  );
}
