/** @format */

import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import {SHIPPINGCODES} from '../../constants/index';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '50%',
  },
  formControl2: {
    margin: theme.spacing(1),
    width: '25%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddCoupon(props) {
  const {open, onClose, coupon = {}} = props;
  const classes = useStyles();
  const [name, setName] = useState('');
  const [discount, setDiscount] = useState(coupon.discount || 0);
  const [type, setType] = useState('amount');
  const [valid, setValid] = useState(false);
  const [tiers, setTiers] = useState([{min: 10, max: 19, discount: 0}]);
  const [expires, setExpires] = useState('');
  const [redemptionsAllowed, setRedemptionsAllowed] = useState(1);
  const [minAmount, setMinAmount] = useState(0);
  const [shippingService, setShippingService] = useState(1);

  function handleClose() {
    if (onClose) {
      let expiration = new Date(expires);
      expiration.setDate(expiration.getDate() + 1);
      let Coupon = {
        name,
        discount: {},
        expiration: expiration.getTime() / 1000,
        redemptionsAllowed,
        shippingService: shippingService || 1,
        minAmount: {
          subtotal: minAmount,
        },
      };
      if (type !== 'tier') {
        Coupon.discount[type] = type === 'amount' ? discount * 100 : discount;
      } else {
        Coupon.discount[type] = tiers;
      }
      onClose(Coupon);
    }
  }
  useEffect(() => {
    if (coupon.type) {
      setType(coupon.type);
      setDiscount(coupon.discount);
      setShippingService(coupon.shippingService || 1);
      let d = new Date(coupon.expiration * 1000);
      let dateStr = `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(
        -2
      )}-${('0' + d.getDate()).slice(-2)}`;
      setExpires(dateStr);
      setRedemptionsAllowed(coupon.redemptionsAllowed);
      if (coupon.type === 'tier') {
        setTiers(coupon.tier);
        setDiscount(1);
      }
    }
  }, [coupon]);
  const handleChange = fieldName => event => {
    const value = event.target.value;

    // let tmp = printer;
    switch (fieldName) {
      case 'name':
        setName(value.toLowerCase());
        break;
      case 'discount':
        let tmp = parseFloat(value);
        if (isNaN(tmp) && value !== '') {
          tmp = 0;
        }
        setDiscount(tmp);
        break;
      case 'expires':
        setExpires(value);
        break;
      case 'type':
        setType(value);
        break;
      case 'shippingService':
        setShippingService(value);
        break;
      default:
        break;
    }

    if (name && expires && (discount || tiers.length > 1)) {
      setValid(true);
    }
  };

  useEffect(() => {
    if (name && discount && expires) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [name, discount, expires]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Coupon</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth={true}
              margin="dense"
              id="name"
              label="Name"
              name="name"
              value={name}
              onChange={handleChange('name')}
            />
          </FormControl>
          <br />
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Discount Type</FormLabel>
            <RadioGroup
              aria-label="type"
              name="type"
              value={type}
              onChange={handleChange('type')}
              row>
              <FormControlLabel value="amount" control={<Radio />} label="$" />
              <FormControlLabel value="percent" control={<Radio />} label="%" />
              <FormControlLabel value="tier" control={<Radio />} label="Tier" />
            </RadioGroup>
          </FormControl>
          <br />
          {type !== 'tier' ? (
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth={true}
                margin="dense"
                id="discount"
                label="Discount"
                name="discount"
                type="number"
                onFocus={() => {
                  if (discount === 0) setDiscount('');
                }}
                value={discount}
                onChange={handleChange('discount')}
              />
            </FormControl>
          ) : (
            <Tier tiers={tiers} setTiers={setTiers} {...props} />
          )}
          <br />
          <FormControl className={classes.formControl}>
            <TextField
              id="date"
              label="Expires on"
              type="date"
              value={expires}
              fullWidth={true}
              margin="dense"
              onChange={handleChange('expires')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <TextField
              margin="dense"
              id="redemptionsAllowed"
              label="Redemptions Allowed"
              name="redemptionsAllowed"
              type="number"
              fullWidth={true}
              value={redemptionsAllowed}
              onChange={e => {
                setRedemptionsAllowed(Number(e.target.value));
              }}
            />
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <TextField
              margin="dense"
              id="minAmount"
              label="Min Amount"
              name="minAmount"
              type="number"
              fullWidth={true}
              value={minAmount}
              onChange={e => {
                setMinAmount(Number(e.target.value));
              }}
            />
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">
              Shipping Service
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={shippingService}
              onChange={handleChange('shippingService')}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {SHIPPINGCODES.map((code, i) => {
                return (
                  <MenuItem key={code.id + '_' + i} value={code.id}>
                    {code.name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              Change to the service required for this coupon.
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary">
            Cancel
          </Button>
          {valid ? (
            <Button onClick={handleClose} color="primary">
              Add
            </Button>
          ) : (
            <Button onClick={handleClose} color="primary" disabled>
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Tier = ({tiers, setTiers}) => {
  const classes = useStyles();

  // const [tiers,setTiers] = useState([{min:10,max:19,discount:0}]);

  const handle = index => e => {
    let value = Number(e.target.value);
    let name = e.target.name;
    let tmp = [...tiers];
    tmp[index][name] = value;
    setTiers(tmp);
  };

  const addRow = () => {
    let tmp = [...tiers];
    let lastMax = tmp[tmp.length - 1].max;
    let newTier = {min: lastMax + 1, max: lastMax + 10, discount: 0};
    tmp.push(newTier);
    setTiers(tmp);
  };

  return tiers.map((item, index) => {
    return (
      <div key={index} style={{display: 'flex', alignItems: 'baseline'}}>
        <FormControl className={classes.formControl2}>
          <TextField
            margin="dense"
            label="Min Qty"
            name="min"
            type="number"
            value={item.min}
            onChange={handle(index)}
          />
        </FormControl>
        <FormControl className={classes.formControl2}>
          <TextField
            margin="dense"
            label="Max Qty"
            name="max"
            type="number"
            value={item.max}
            onChange={handle(index)}
          />
        </FormControl>
        <FormControl className={classes.formControl2}>
          <TextField
            margin="dense"
            label="Discount %"
            name="discount"
            type="number"
            value={item.discount}
            onChange={handle(index)}
          />
        </FormControl>
        {index === tiers.length - 1 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={addRow}
            style={{height: '20%'}}>
            +
          </Button>
        )}
      </div>
    );
  });
};
