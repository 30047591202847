/** @format */

import React, {useState, useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: 600,
    position: 'relative',
    color: theme.palette.text.secondary,
    '& iframe': {
      border: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      padding: 10,
    },
  },
  cam: {
    width: '100%',
  },
}));

let loaded;

export default function Line({addAlert, line}) {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  useEffect(() => {
    clearTimeout(loaded);
    if (loading === true) {
      loaded = setTimeout(() => {
        setLoading(false);
        addAlert(
          'There was an error loading extruder data. Are you in B4?',
          'error'
        );
      }, 10000);
    }
    // eslint-disable-next-line
  }, [loading]);

  const prodUrl = `https://monitor${line}.printbed.com`;
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          {loading && <CircularProgress />}
          <iframe
            src={prodUrl}
            width="100%"
            height="100%"
            id="prodFrame"
            title="Production"
            style={{height: '100%'}}
            onLoad={() => {
              setLoading(false);
            }}
            onError={() => {
              addAlert('There was an error loading extruder data.', 'error');
            }}
            frameBorder="0"
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <img
            src={`https://extruder${line}.printbed.com/stream/video.mjpeg`}
            alt="Extruder Cam"
            className={classes.cam}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
