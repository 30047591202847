/** @format */

import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Editor} from '@tinymce/tinymce-react';

const TINY_API_KEY = 'i7bqf4hu2elh07hypt3m9o9d17sq0743v377wzwg947kvxt6';
const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  accordianDetails: {
    flexDirection: 'column',
  },
}));

const tagsOptions = {
  colors: 'color',
  material: 'material',
  amount: 'weight',
  diameter: 'diameter',
};

export default function AddItem({openItem, handleItemClose, addAlert, id}) {
  const classes = useStyles();
  const [item, setItem] = useState({});
  const [name, setName] = useState('');
  const [pmsColor, setPmsColor] = useState('');
  const [desc, setDesc] = useState('');
  const editorRef = useRef();
  // useEffect(() => {
  //   setItem({...template, nsg: true});
  //   createName();
  // }, [template]);

  useEffect(() => {
    getItem();
  }, []);

  const getItem = () => {
    let url = `/api/item/` + id;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify(tmp),
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(resp => {
        //open dialog
        if (!resp.imgSrc) {
          resp.imgSrc = [];
        }
        setItem(resp);
        setDesc(resp.desc);
      })
      .catch(e => {
        addAlert(e.message, 'error');
      });
  };

  const createName = () => {
    let {options} = item;
    if (options) {
      let {
        amount = [''],
        colors = [''],
        diameter = [''],
        material = [''],
      } = options;
      const newName = `${colors[0].toTitleCase()} ${material[0]} ${amount[0]} ${
        diameter[0]
      }`;
      setName(newName);
    }
  };

  const setOptionHandler = field => {
    const {name, value} = field;
    let tmp = {...item};
    tmp.options[name] = [value];
    tmp.tags[tagsOptions[name]] = value;
    if (name === 'colors') {
      tmp.tags['colorName'] = value.toTitleCase();
    }
    setItem(tmp);
    createName();
  };

  const setHandler = field => {
    const {name, value, type, checked} = field;
    let tmp = {...item};
    switch (type) {
      case 'number': {
        let num = Number(value);
        if (!isNaN(num)) {
          tmp[name] = num;
        }
        break;
      }
      case 'checkbox': {
        tmp[name] = checked;
        break;
      }
      default: {
        if (name.indexOf('.') > 0) {
          let opt = name.split('.');
          if (!tmp[opt[0]]) {
            tmp[opt[0]] = {};
          }
          tmp[opt[0]][opt[1]] = [value];
        } else {
          tmp[name] = value;
        }
        break;
      }
    }
    setItem(tmp);
  };

  const submit = () => {
    let url = `/api/item/` + id;

    let tmp = {...item};
    if (name && !tmp.name) {
      tmp.name = name;
    }

    tmp.desc = editorRef.current.getContent();
    const regex = /<span>PMS Color : ([^<]+)<\/span>/;
    const match = tmp.desc.match(regex);

    if (match && pmsColor) {
      let pmsColorValue = match[1];
      tmp.desc = tmp.desc.replace(
        pmsColorValue,
        `<span>PMS Color : ${pmsColor}</span>`
      );
    } else if (match) {
      let pmsColorValue = match[1];
      tmp.desc = tmp.desc.replace(pmsColorValue, '');
    } else if (pmsColor) {
      tmp.desc = tmp.desc.replace(
        '</ul>',
        `<li><span>PMS Color : ${pmsColor}</span></li></ul>`
      );
    }

    fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tmp),
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(resp => {
        //open dialog
        // console.log(resp);
        addAlert(`${tmp.name} has been updated.`, 'success');
        handleItemClose();
      })
      .catch(e => {
        addAlert(e.message, 'error');
      });
  };

  const onClose = () => {
    handleItemClose();
  };

  return (
    <Dialog
      open={openItem}
      // onClose={handleItemClose}
      disableEnforceFocus={true}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Add new Inventory Item</DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate onSubmit={onClose}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={item.name}
            onChange={e => setHandler(e.target)}
            autoComplete=""
            autoFocus
          />
          {item.options &&
            Object.keys(item.options).map(key => {
              return (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name={key}
                  label={key.toTitleCase()}
                  value={item.options[key][0] || ''}
                  id={key}
                  key={key}
                  onChange={e => setOptionHandler(e.target)}
                />
              );
            })}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="number"
            name="price"
            label="Price"
            value={(item.price / 100).toFixed(2) || ''}
            id="price"
            onChange={e => setHandler(e.target)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="text"
            name="PMS Color"
            label="PMS color"
            value={pmsColor}
            id="pms_color"
            onChange={e => {
              let value = e.target.value;
              setPmsColor(value);
            }}
          />
          <Editor
            apiKey={TINY_API_KEY}
            onInit={(_evt, editor) => (editorRef.current = editor)}
            initialValue={item.desc}
            value={desc}
            onEditorChange={newValue => {
              setDesc(newValue);
            }}
            init={{
              height: 500,
              menubar: true,
              advcode_inline: true,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'code',
                'help',
                'wordcount',
              ],
              toolbar:
                'undo redo print spellcheckdialog formatpainter | blocks | bold italic underline forecolor backcolor | link image | alignleft aligncenter alignright alignjustify | code',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography className={classes.heading}>Images</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianDetails}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="Img 1"
                label="Img 1"
                value={item.imgSrc ? item.imgSrc[0] : ''}
                id="img1"
                onChange={e => {
                  let value = e.target.value;
                  setItem(prevItem => {
                    prevItem.imgSrc[0] = value;
                    return prevItem;
                  });
                }}
              />
              <br />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="Img 2"
                label="Img 2"
                value={item.imgSrc ? item.imgSrc[1] : ''}
                id="img1"
                onChange={e => {
                  let value = e.target.value;
                  setItem(prevItem => {
                    prevItem.imgSrc[1] = value;
                    return prevItem;
                  });
                  // setPmsColor(value);
                }}
              />
              <br />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="Img 3"
                label="Img 3"
                value={item.imgSrc ? item.imgSrc[2] : ''}
                id="img1"
                onChange={e => {
                  let value = e.target.value;
                  setItem(prevItem => {
                    prevItem.imgSrc[2] = value;
                    return prevItem;
                  });
                  // setPmsColor(value);
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="Img 4"
                label="Img 4"
                value={item.imgSrc ? item.imgSrc[3] : ''}
                id="img1"
                onChange={e => {
                  let value = e.target.value;
                  setItem(prevItem => {
                    prevItem.imgSrc[3] = value;
                    return prevItem;
                  });
                  // setPmsColor(value);
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="Img 5"
                label="Img 5"
                value={item.imgSrc ? item.imgSrc[4] : ''}
                id="img1"
                onChange={e => {
                  let value = e.target.value;
                  setItem(prevItem => {
                    prevItem.imgSrc[4] = value;
                    return prevItem;
                  });
                  // setPmsColor(value);
                }}
              />
            </AccordionDetails>
          </Accordion>
          <br />
          <FormControl variant="outlined" fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              style={{backgroundColor: 'white', padding: 4}}>
              Unit of Measure
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="uom"
              variant="outlined"
              defaultValue="each"
              // value={age}
              onChange={e => setHandler(e.target)}>
              <MenuItem value="each">each</MenuItem>
              <MenuItem value="kg">kg</MenuItem>
              <MenuItem value="ft^2">sqft</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                name="nsg"
                color="primary"
                checked={item.nsg || false}
                onChange={e => setHandler(e.target)}
              />
            }
            label="Non-Sellable Good"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={submit} variant="outlined" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
