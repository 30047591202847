/** @format */

import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Shipping from '../orders/address';
import Boxes from './addbox';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: 600,
    position: 'relative',
    color: theme.palette.text.secondary,
    '& iframe': {
      border: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      padding: 10,
    },
  },
  shippingButton: {
    minHeight: 40,
    marginRight: 10,
  },
}));

export default function Shipment({zebraPrinter}) {
  const [shipping, setShipping] = useState({});
  const [boxes, setBoxes] = useState([]);
  const [rates, setRates] = useState([]);
  const classes = useStyles();

  const handleChange = data => {
    if (typeof data === 'object') {
      setShipping(data);
    }
  };

  const printShippingLabel = labels => {
    labels.forEach(t => {
      if (t.encodedLabel) {
        zebraPrinter.send(
          atob(t.encodedLabel),
          () => {},
          e => {
            console.error(e);
          }
        );
      } else {
        zebraPrinter.convertAndSendFile(
          `/api/shipping/zpl?url=${t.label}&type=.zpl`,
          () => {},
          e => {
            console.error(e);
          }
        );
      }
    });
  };

  const getShippingLabel = () => {
    let recipient = {};

    Object.keys(shipping).map(key => {
      return (recipient[key] = shipping[key].code || shipping[key].value);
    });

    let body = {
      boxes,
      recipient,
    };
    let url = `/api/shipping/`;
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        printShippingLabel(resp);
        // open dialog
      })
      .catch(e => {
        console.error(e);
      });
  };

  const getShippingRates = () => {
    let qs = {
      postalCode: shipping.zipCode.value,
      boxes,
    };
    let url = `/api/shipping/rates?postalCode=${
      shipping.zipCode.value
    }&boxes=${JSON.stringify(boxes)}`;
    fetch(url, {
      method: 'GET',
      qs,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        console.log(resp);
        setRates(resp);
        // open dialog
      })
      .catch(e => {
        console.error(e);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <h2>Address</h2>
        <Shipping updateFunc={handleChange} />
      </Grid>
      <Grid item xs={6}>
        <h2>Packages</h2>
        <Boxes onChange={setBoxes} />

        <h2>Options</h2>
        <Button
          color="primary"
          title="Get Shipping Rates"
          variant="outlined"
          className={classes.shippingButton}
          onClick={getShippingRates}>
          <i className="fas fa-clipboard-list"></i>
        </Button>
        {zebraPrinter && (
          <Button
            color="primary"
            title="Print Shipping Label"
            variant="outlined"
            className={classes.shippingButton}
            onClick={getShippingLabel}>
            <i className="fab fa-fedex fa-2x"></i>
          </Button>
        )}
        {rates.length > 0 && (
          <>
            <h2>Rates</h2>
            {rates.map(r => {
              return (
                <li key={r.service}>
                  {r.service} ${r.rate.toFixed(2)}
                </li>
              );
            })}
          </>
        )}
      </Grid>
    </Grid>
  );
}
