/** @format */

import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Title from '../../components/title';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CopyIcon from '@material-ui/icons/FileCopy';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import {useConfirm} from 'material-ui-confirm';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AddCoupon from './addCoupon';

import TablePaginationActions from '../../components/common/TablePaginationActions';

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    textAlign: 'left',
  },
  chips: {
    margin: theme.spacing(0.5),
    display: 'inline-flex',
  },
}));
let couponExpiration;
export default function Orders(props) {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [addCoupon, setaAddCoupon] = useState(false);
  const [copyCoupon, setCopyCoupon] = useState({});
  const {addAlert} = props;
  const confirm = useConfirm();
  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(Number(e.target.value));
  };

  const openPrinterDialog = () => {
    setaAddCoupon(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCoupons = (page, rowsPerPage) => {
    let url = `/api/coupons/?skip=${page * rowsPerPage}&limit=${rowsPerPage}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        //open dialog
        setOrders(resp);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const closeCoupon = data => {
    if (!data) {
      setaAddCoupon(false);
    } else {
      let url = `/api/coupons`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(async res => {
          if (res.ok) {
            return res.json();
          } else {
            throw await res.text();
          }
        })
        .then(resp => {
          //open dialog
          getCoupons(page, rowsPerPage);
          setaAddCoupon(false);
        })
        .catch(e => {
          addAlert(e, 'error');
          // console.error(e);
        });
    }
  };

  useEffect(() => {
    getCoupons(page, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const expireCoupon = name => () => {
    confirm({
      title: 'Expire Coupon?',
      description: 'Are you sure you want to expire this coupon?',
    })
      .then(() => {
        let expiration = Math.floor(new Date().getTime() / 1000);
        updateCoupon(name, expiration);
      })
      .catch(() => {});
  };

  const updateDate = value => {
    couponExpiration = value + 86400;
  };
  const extendCoupon = name => () => {
    confirm({
      title: 'Extend Coupon?',
      description: 'Are you sure you want to extend this coupon?',
      content: <ExpireDialog onChangeDate={updateDate} />,
    })
      .then(() => {
        updateCoupon(name, couponExpiration);
      })
      .catch(() => {});
  };

  const updateCoupon = (name, expiration) => {
    let url = `/api/coupons/` + name;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({
        expiration,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(async res => {
        if (res.ok) {
          return res.json();
        } else {
          throw await res.text();
        }
      })
      .then(resp => {
        getCoupons(page, rowsPerPage);
      })
      .catch(e => {
        addAlert(e, 'error');
      });
  };

  const copy = index => () => {
    let tmp = orders[index];
    if (tmp.discount.amount) {
      tmp.discount = tmp.discount.amount;
      tmp.type = 'amount';
    } else if (tmp.discount.percent) {
      tmp.discount = tmp.discount.percent;
      tmp.type = 'percent';
    } else if (tmp.discount.tier) {
      tmp.tier = tmp.discount.tier;
      tmp.discount = 0;
      tmp.type = 'tier';
    }
    setCopyCoupon(tmp);
    setaAddCoupon(true);
  };
  return (
    <Grid container spacing={3}>
      {/* Chart */}
      <Grid item xs={4} className={classes.paper}>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={openPrinterDialog}>
          <AddIcon />
        </Fab>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Title>Coupons</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell># of Redemptions</TableCell>
                <TableCell>Min Amount</TableCell>
                <TableCell>Expires</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  {row.discount.tier ? (
                    <TableCell>
                      <ul>
                        {row.discount.tier.map((tier, index) => {
                          return (
                            <li key={index}>
                              {tier.min} - {tier.max} {tier.discount}% off
                            </li>
                          );
                        })}
                      </ul>
                    </TableCell>
                  ) : (
                    <TableCell>
                      {row.discount.amount
                        ? '$' + (row.discount.amount / 100).toFixed(2)
                        : row.discount.percent + '%'}
                    </TableCell>
                  )}
                  <TableCell>{row.redemptionsAllowed}</TableCell>
                  <TableCell>{row.minAmount.subtotal}</TableCell>
                  <TableCell>
                    {new Date(row.expiration * 1000).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup
                      color="primary"
                      aria-label="outlined primary button group">
                      <Button title="Copy Coupon" onClick={copy(i)}>
                        <CopyIcon />
                      </Button>
                      <Button
                        title="Extend Coupon"
                        onClick={extendCoupon(row.name)}>
                        <EventAvailableIcon />
                      </Button>
                      <Button
                        title="Expire Coupon"
                        onClick={expireCoupon(row.name)}>
                        <EventBusyIcon />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  colSpan={6}
                  count={orders.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>
      <AddCoupon open={addCoupon} coupon={copyCoupon} onClose={closeCoupon} />
    </Grid>
  );
}

const ExpireDialog = ({onChangeDate}) => {
  const classes = useStyles();
  return (
    <div>
      <FormControl className={classes.formControl}>
        <TextField
          id="date"
          label="Expires on"
          type="date"
          // value={expires}
          fullWidth={true}
          margin="dense"
          onChange={e => {
            let value = new Date(e.target.value).getTime() / 1000;
            onChangeDate(value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
    </div>
  );
};
