/** @format */

import React, {useState} from 'react';
// Material UI imports
import {withStyles} from '@material-ui/core/styles';
// Custom Component Imports
import ShippingInput, {ShippingSelect} from './shipping-input';
// Styling import
import ShippingStyles from './styles';

const PaymentDetails = props => {
  const {classes, updateFunc = () => {}, showErrors = false} = props || {};
  const [paymentInfo, setPaymentInfo] = useState({
    orderId: {value: '', isValid: false},
    total: {value: '', isValid: false},
    type: {
      value: 'Promo',
      code: 'promo',
      isValid: true,
    },
    note: {value: '', isValid: true},
  });
  // TODO
  // Make API call to get valid shipping countries
  const saleTypes = [
    {name: 'Promo', code: 'promo'},
    {name: 'Amazon', code: 'amazon'},
    {name: 'Walmart', code: 'walmart'},
    {name: 'Ebay', code: 'ebay'},
    {name: 'Invoice', code: 'invoice'},
    {name: 'Test', code: 'test'},
  ];

  const handleChange = event => {
    event.preventDefault();
    const dataType = event.target.id.split('-')[1];
    let newValue = event.target.value;
    const newCustomerInfo = {...paymentInfo};
    newCustomerInfo[dataType].value = newValue;

    const trimVal = newValue.replaceAll(' ', '');
    let valid = false;
    switch (dataType) {
      case 'orderId':
        valid = trimVal.length >= 2;
        break;
      case 'total':
        valid = !isNaN(newValue);
        break;
      case 'type':
        let types = saleTypes.map(i => i.code);
        valid = types.indexOf(newValue) >= 0;
        break;
      default:
        valid = newValue !== '';
        break;
    }
    newCustomerInfo[dataType].isValid = valid;

    // console.log(dataType,newValue,newCustomerInfo)
    setPaymentInfo(newCustomerInfo);
    // handleValidation(event);
    updateFunc(newValue, dataType);
  };

  const handleValidation = event => {
    const dataType = event.target.id.split('-')[1];
    const value = event.target.value;
    const trimVal = value.replaceAll(' ', '');
    let valid = false;

    switch (dataType) {
      case 'orderId':
        valid = trimVal.length >= 2;
        break;
      case 'total':
        valid = value !== '' && !isNaN(value);
        break;
      case 'type':
        let types = saleTypes.map(i => i.code);
        valid = types.indexOf(value) >= 0;
        break;
      default:
        valid = value !== '';
        break;
    }
    const newCustomerInfo = paymentInfo;
    newCustomerInfo[dataType].isValid = valid;
    setPaymentInfo(newCustomerInfo);
    updateFunc(newCustomerInfo);
  };

  const calcShowErrs = valName => {
    const isEmptyVal = paymentInfo[valName].value === '';
    return (
      (isEmptyVal && showErrors) ||
      (!isEmptyVal && !paymentInfo[valName].isValid)
    );
  };

  return (
    <div className={classes.wrapper} key="shipping-wrapper">
      <ShippingSelect
        addedClasses="inp"
        required
        label="Type"
        id="inp-type"
        value={paymentInfo.type}
        passedOnChange={e => {
          const newValue = e.target.value;
          const newCustomerInfo = {...paymentInfo};
          newCustomerInfo['type'] = {
            value: saleTypes.find(c => c.code === newValue).name,
            code: newValue,
            isValid: true,
          };
          setPaymentInfo(newCustomerInfo);
        }}
        values={saleTypes}
        isError={showErrors && !paymentInfo.type.isValid}
        errorText="This field is required"
      />
      <ShippingInput
        label={paymentInfo.type.value !== 'Promo' ? 'Order Id' : 'URL'}
        id="inp-orderId"
        required
        value={paymentInfo.orderId.value}
        onChange={handleChange}
        onBlur={handleValidation}
        isError={calcShowErrs('orderId')}
        errorText="This field is required"
      />
      <ShippingInput
        required
        label="Total"
        id="inp-total"
        value={paymentInfo.total.value}
        onChange={handleChange}
        onBlur={handleValidation}
        isError={calcShowErrs('total')}
        errorText="This field is required and should be a number"
      />
    </div>
  );
};

export default withStyles(ShippingStyles)(PaymentDetails);
