/** @format */

import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import {Button} from '@material-ui/core';
import AddOrder from './addOrder';

// import CreateLabel from './createLabel';
const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    textAlign: 'left',
  },
  chips: {
    margin: theme.spacing(0.5),
    display: 'inline-flex',
  },
  formControl: {
    width: '100%',
  },
  readFormControl: {
    width: '100%',
  },
  textfield: {
    width: '100%',
    margin: 10,
  },
  selectLabel: {
    top: 2,
    left: 22,
  },
  button: {
    marginRight: 50,
  },
  cost: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function Orders(props) {
  const {history, location, addAlert} = props;
  const {qs} = location;
  const [weight, setWeight] = useState('');
  const [qty, setQty] = useState('');
  const [material, setMaterial] = useState('');
  const [materials, setMaterials] = useState([]);
  const [printTime, setPrintTime] = useState('');
  const [shipping, setShipping] = useState('');
  const [costPerHour, setCostPerHour] = useState('5.00');
  const [openItem, setOpenItem] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const [laberTooLow, setLaberTooLow] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    let url = `/api/items?q=nsg=false&limit=200`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(resp => {
        let items = resp.filter(item => {
          return item.data && item.data.temp;
        });

        setMaterials(items);
        setMaterial(items[0].id);
      })
      .catch(e => {
        addAlert(e.message, 'error');
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLaberTooLow(electricCost() > getLabor());
  }, [printTime, costPerHour]);

  const getCost = () => {
    const item = materials.filter(i => i.id === material)[0];
    if (!item) {
      return 0;
    }
    const {price = 0, tags} = item;
    let w = 1000;
    if (tags.weight.indexOf('kg') < 0) {
      w = Number(tags.weight.replace('g', ''));
    } else {
      w = Number(tags.weight.replace('kg', '')) * 1000;
    }
    const costPerGram = price / 100 / w;
    return costPerGram * Number(weight);
  };

  const electricCost = () => {
    const VoronAvgPower = 0.25; //in Kw/hour
    const MiAvgKWHCost = 0.19; // KW/hour cost in michigan avg

    return VoronAvgPower * printTime * MiAvgKWHCost * qty;
  };

  const getLabor = () => {
    const tmp = Number(costPerHour) || 0;
    return tmp * Number(printTime);
  };

  const clearFields = () => {
    setPrintTime('');
    setShipping('');
    setQty('');
    setWeight('');
  };

  const handleItemClose = () => {
    setOpenItem(false);
  };

  const createOrder = () => {
    const Item = materials.find(item => item.id === material);
    console.log(material, Item);
    setOrderInfo({
      id: new Date().getTime() + 'pod',
      price: Number((getLabor() + getCost()).toFixed(2)),
      qty,
      images: 'https://cdn.sunsh1n3.com/Images/printbed/3DPrint.jpg',
      options: {
        ...Item.tags,
        weight,
      },
      shippingCost: Number(shipping),
    });
    setOpenItem(true);
  };

  return (
    <Grid container spacing={3}>
      <Paper className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="item-label" className={classes.selectLabel}>
                &nbsp;Item
              </InputLabel>
              <Select
                labelId="item-label"
                id="item"
                label="Item"
                className={classes.textfield}
                variant="outlined"
                fullwidth={true}
                name="id"
                onChange={e => {
                  const val = e.target.value;
                  setMaterial(e.target.value);
                }}
                value={material}>
                {materials.map(mat => {
                  return (
                    <MenuItem key={mat.id} value={mat.id}>
                      {mat.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                // margin="normal"
                name="weight"
                value={weight}
                fullWidth={true}
                label="Weight in grams"
                id="weight"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment>g</InputAdornment>,
                }}
                className={classes.textfield}
                onChange={e => {
                  let value = Number(e.target.value);
                  setWeight(value);
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                // margin="normal"
                name="printime"
                value={printTime}
                label="Print Time in Hours (round to the nearest 30 minutes)"
                id="printTime"
                type="number"
                fullWidth={true}
                InputProps={{
                  endAdornment: <InputAdornment>Hrs</InputAdornment>,
                }}
                className={classes.textfield}
                onChange={e => {
                  let value = Number(e.target.value);
                  setPrintTime(value);
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                // margin="normal"
                name="qty"
                value={qty}
                fullWidth={true}
                label="Qty"
                id="qty"
                type="number"
                className={classes.textfield}
                onChange={e => {
                  let value = Number(e.target.value);
                  setQty(value);
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                // margin="normal"
                name="costperhour"
                value={costPerHour}
                fullWidth={true}
                label="Cost Per Hour"
                id="costperhour"
                type="text"
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                className={classes.textfield}
                onChange={e => {
                  let value = e.target.value;
                  setCostPerHour(value);
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                margin="normal"
                name="shipping"
                value={shipping}
                fullWidth={true}
                label="Shipping Cost"
                id="shipcost"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                className={classes.textfield}
                onChange={e => setShipping(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} className={classes.cost} direction="column">
            <FormControl className={classes.readFormControl}>
              <TextField
                // variant="outlined"
                margin="normal"
                name="Cost"
                value={getCost().toFixed(2)}
                fullWidth={true}
                label="Material Cost per Item"
                id="cost"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                className={classes.textfield}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
            <FormControl className={classes.readFormControl}>
              <TextField
                // variant="outlined"
                margin="normal"
                name="Cost"
                value={getLabor().toFixed(2)}
                fullWidth={true}
                label="Labor Cost"
                error={laberTooLow}
                helperText={
                  laberTooLow
                    ? 'This is less that the estimated Electricity cost.'
                    : ''
                }
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                id="cost"
                type="number"
                className={classes.textfield}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
            {/* <FormControl className={classes.readFormControl}>
                <TextField
                  // variant="outlined"
                  margin="normal"
                  name="electricCost"
                  value={electricCost().toFixed(2)}
                  fullWidth={true}
                  label="~Electricity Cost"
                  color="secondary"
                  InputProps={{
                    startAdornment:<InputAdornment>$</InputAdornment>
                  }}
                  id="electriccost"
                  type="number"
                  className={classes.textfield}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  
                />
              </FormControl> */}
            <FormControl className={classes.readFormControl}>
              <TextField
                // variant="outlined"
                margin="normal"
                name="total"
                value={(
                  (getLabor() + getCost()) * qty +
                  Number(shipping)
                ).toFixed(2)}
                fullWidth={true}
                label="Total Cost"
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                id="totalcost"
                type="number"
                className={classes.textfield}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Button
              color="info"
              className={classes.button}
              variant="contained"
              onClick={clearFields}>
              Clear
            </Button>
            <Button color="primary" variant="contained" onClick={createOrder}>
              Create Order
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <AddOrder
        openItem={openItem}
        addAlert={addAlert}
        orderInfo={orderInfo}
        handleItemClose={handleItemClose}
      />
    </Grid>
  );
}
