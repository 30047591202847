/**
 * /* eslint-disable no-script-url
 *
 * @format
 */

import React from 'react';
// import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from '../title';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

const Deposits = props => {
  const {dailyTotals} = props;
  const today = dailyTotals[dailyTotals.length - 1];
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Daily Totals</Title>
      <Typography component="p" variant="h3">
        {today ? today.total : 0}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        on {new Date().toLocaleDateString()}
      </Typography>
      <Typography component="p" className={classes.depositContext}>
        {today ? today.subs : 0} Active Subs
      </Typography>
      <div>
        {/* <Link color="primary" href="javascript:;">
            View balance
          </Link> */}
      </div>
    </React.Fragment>
  );
};

export default Deposits;
