/** @format */

import React from 'react';
// Material UI Component Imports
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
// Material UI Icon Imports
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {InputStyles} from './styles';

export default function ShippingInput({
  type = 'text',
  placeholder = '',
  errorText = 'Invalid entry',
  required = false,
  label = '',
  id = null,
  isError = false,
  fullWidth = true,
  onChange = e => {
    console.log('default ShippingInput onChange func');
  },
  onBlur = e => {
    console.log('default ShippingInput onBlur func');
  },
  value = '',
  multiline = false,
  rows = 1,
  addedClasses = '',
  inputProps = {},
}) {
  const inpStyles = InputStyles();
  let inputId = null;
  if (id === null) {
    if (label === '') {
      inputId = 'custom-input-' + Math.floor(Math.random() * 100).toString();
    } else {
      inputId = `custom-${label}-input`;
    }
  } else {
    inputId = id;
  }
  return (
    <div className={inpStyles.wrapper + ' ' + addedClasses}>
      <TextField
        variant="outlined"
        multiline={multiline}
        rows={rows}
        label={label}
        id={inputId}
        className={inpStyles.actualInput}
        type={type}
        placeholder={placeholder}
        error={isError}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{...inputProps}}
      />
      <FormHelperText
        className={inpStyles.errorTxt + ' ' + (isError ? 'invalid' : 'valid')}
        error={isError}>
        {errorText}
      </FormHelperText>
    </div>
  );
}

export function ShippingSelect({
  errorText = 'Invalid entry',
  required = false,
  label = '',
  id = null,
  isError = false,
  fullWidth = true,
  passedOnChange = e => {
    console.log('default ShippingInput onChange func');
  },
  onBlur = e => {
    console.log('default ShippingInput onBlur func');
  },
  values = ['none'],
  addedClasses = '',
  value = '',
}) {
  const inpStyles = InputStyles();
  value = value || values[0];
  isError = false;
  let inputId = null;
  if (id === null) {
    if (label === '') {
      inputId = 'custom-input-' + Math.floor(Math.random() * 100).toString();
    } else {
      inputId = `custom-${label}-input`;
    }
  } else {
    inputId = id;
  }
  return (
    <div className={inpStyles.wrapper + ' ' + addedClasses}>
      <InputLabel
        className={inpStyles.label + (label === '' ? ' no-lbl' : '')}
        htmlFor={inputId}
        disableAnimation>
        {label}
        {required ? '*' : ''}
      </InputLabel>
      <Select
        variant="outlined"
        value={value.code}
        label={label}
        onChange={e => {
          passedOnChange(e);
        }}
        id={inputId}
        className={inpStyles.selector}
        IconComponent={ExpandMoreIcon}
        error={isError}
        MenuProps={{
          variant: 'menu',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}>
        {values.length > 0
          ? values.map((val, i) => {
              return (
                <MenuItem
                  key={val.code + '-' + val.name + '_' + i}
                  value={val.code}>
                  {val.name}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </div>
  );
}
