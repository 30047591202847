/** @format */

import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';

import Chart from '../../components/chart';
import Pie from '../../components/chart/pie';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 500,
  },
  toggleButton: {
    backgroundColor: 'blue',
  },
}));
const today = new Date().toISOString().substring(0, 10);
let yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
yesterday = yesterday.toISOString().substring(0, 10);
export default function Reporting() {
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(today);
  const [data, setData] = useState([]);
  const [colorData, setColorData] = useState({});
  const [pollAnswers, setPollAnswers] = useState([]);
  const [groupBy, setGroupBy] = useState('day');
  useEffect(() => {
    if (!startDate) {
      return;
    }
    let url = `/api/stats/?start=${startDate}&end=${endDate}&weekly=true`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        const {stats, colorStats} = resp;
        stats.sort((a, b) => (a.day > b.day ? 1 : -1));
        setData(stats);

        let tmp = {All: []};
        let miniColors = {};
        let miniTotals = 0;
        colorStats.forEach(c => {
          if (!tmp[c.material]) {
            tmp[c.material] = [];
          }

          if (c.material === 'MINI') {
            if (c.color === 'ALL') {
              miniTotals += c.count;
            } else {
              if (!miniColors[c.color]) {
                miniColors[c.color] = 0;
              }
              miniColors[c.color] += c.count;
            }
          } else {
            tmp[c.material].push(c);
            tmp.All.push(c);
          }
        });

        Object.keys(miniColors).forEach(color => {
          tmp.MINI.push({count: miniColors[color], color, material: 'PLA'});
        });

        if (miniTotals > 0) {
          tmp.All.push({count: miniTotals, color: 'MINI', material: 'PLA'});
        }
        setColorData(tmp);
      })
      .catch(e => {
        console.error(e);
      });
  }, [startDate, endDate]);

  useEffect(() => {
    getSubVotes();
  }, []);
  const getSubVotes = () => {
    fetch(`/api/subscription/vote`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(resp => {
        setPollAnswers(
          resp.options.map(o => {
            return {count: o.votes, color: o.option, material: o.option};
          })
        );
      });
  };

  const handleGroupBy = (e, value) => {
    if (value) {
      setGroupBy(value);
    }
  };

  const getLastSunday = d => {
    var t = new Date(d);
    t.setDate(t.getDate() - t.getDay());
    return t;
  };

  const groupData = () => {
    switch (groupBy) {
      case 'month':
        let monthlyTotals = {};
        data.forEach(d => {
          let lastSunday = new Date(d.day).toISOString().substring(0, 7);
          if (!monthlyTotals[lastSunday]) {
            monthlyTotals[lastSunday] = 0;
          }
          monthlyTotals[lastSunday] += d.total;
        });
        return Object.keys(monthlyTotals).map(key => {
          return {day: key, total: monthlyTotals[key]};
        });
      case 'week':
        let weeklyTotals = {};
        data.forEach(d => {
          let lastSunday = getLastSunday(d.day).toISOString().substring(0, 10);
          if (!weeklyTotals[lastSunday]) {
            weeklyTotals[lastSunday] = 0;
          }
          weeklyTotals[lastSunday] += d.total;
        });
        return Object.keys(weeklyTotals).map(key => {
          return {day: key, total: weeklyTotals[key]};
        });
      default:
        return data;
    }
  };

  const groupDataRevenue = () => {
    switch (groupBy) {
      case 'month':
        let monthlyTotals = {};
        data.forEach(d => {
          let lastSunday = new Date(d.day).toISOString().substring(0, 7);
          if (!monthlyTotals[lastSunday]) {
            monthlyTotals[lastSunday] = 0;
          }
          monthlyTotals[lastSunday] += d.revenue;
        });
        return Object.keys(monthlyTotals).map(key => {
          return {day: key, total: monthlyTotals[key]};
        });
      case 'week':
        let weeklyTotals = {};
        data.forEach(d => {
          let lastSunday = getLastSunday(d.day).toISOString().substring(0, 10);
          if (!weeklyTotals[lastSunday]) {
            weeklyTotals[lastSunday] = 0;
          }
          weeklyTotals[lastSunday] += d.revenue;
        });
        return Object.keys(weeklyTotals).map(key => {
          return {day: key, total: weeklyTotals[key]};
        });
      default:
        // let tmp = [...data];
        let tmp = JSON.parse(JSON.stringify(data));
        tmp.forEach(d => {
          d.total = d.revenue;
        });
        return tmp;
    }
  };

  return (
    <Grid container spacing={3}>
      {/* Chart */}
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <Chart
            dailyTotals={groupData()}
            title={`Sales by ${groupBy.toTitleCase()}`}
          />
          <div style={{display: 'flex', alignItems: 'end'}}>
            <TextField
              id="date"
              label="Start Date"
              type="date"
              style={{maxWidth: 200, marginRight: 20}}
              value={startDate}
              onChange={e => {
                setStartDate(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              label="End Date"
              type="date"
              style={{maxWidth: 200, marginRight: 20}}
              value={endDate}
              onChange={e => {
                setEndDate(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <ToggleButtonGroup
              value={groupBy}
              exclusive
              onChange={handleGroupBy}
              size="small"
              aria-label="text alignment">
              <ToggleButton value="day" aria-label="left aligned">
                Day
              </ToggleButton>
              <ToggleButton value="week" aria-label="centered">
                Weekly
              </ToggleButton>
              <ToggleButton value="month" aria-label="right aligned">
                Monthly
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <Chart
            dailyTotals={groupDataRevenue()}
            unit="Sales (Revenue)"
            title={`Revenue by ${groupBy.toTitleCase()}`}
            currency={true}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={fixedHeightPaper}>
          <Pie colorsTotals={pollAnswers} title="Subscriptions Votes" />
        </Paper>
      </Grid>
      {Object.keys(colorData).map(key => {
        console.log(colorData[key]);
        return (
          <Grid key={key} item xs={6}>
            <Paper className={fixedHeightPaper}>
              <Pie colorsTotals={colorData[key]} title={key} />
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}
