/** @format */
const SHIPPINGCODES = [
  {
    id: 0,
    name: 'local pickup',
  },
  {
    id: 1,
    name: 'fedex priority overnight',
  },
  {
    id: 101,
    name: 'internation priority economy',
  },
  {
    id: 5,
    name: 'fedex standard overnight',
  },
  {
    id: 88,
    name: 'fedex 2 day',
    code: 'FEDEX_2_DAY',
  },
  {
    id: 6,
    name: 'fedex first overnight',
  },
  {
    id: 106,
    name: 'fedex international first',
  },
  {
    id: 18,
    name: 'fedex ip direct distribution',
  },
  {
    id: 20,
    name: 'fedex economy',
  },
  {
    id: 39,
    name: 'fedex first overnight freight',
  },
  {
    id: 49,
    name: 'fedex 2day AM',
  },
  {
    id: 70,
    name: 'fedex 1day freight',
  },
  {
    id: 170,
    name: 'international priority freight',
  },
  {
    id: 80,
    name: 'fedex 2 day freight',
  },
  {
    id: 83,
    name: 'fedex 3 day freight',
  },
  {
    id: 84,
    name: 'ip direct distribution freight',
  },
  {
    id: 84,
    name: 'ip direct distribution freight',
  },
  {
    id: 186,
    name: 'international economy freight',
  },
  {
    id: 102,
    name: 'international priority express',
  },
  {
    id: 95,
    name: 'fedex ground economy',
  },
  {
    id: 952,
    name: 'fedex smartpost media',
  },
  {
    id: 951,
    name: 'fedex smartpost bound printed matter',
  },
  {
    id: 92,
    name: 'fedex ground service',
  },
  {
    id: 90,
    name: 'fedex home delivery',
  },
  {
    id: 200,
    name: 'fedex international economy',
  },
];
const STATUSES = [
  'waiting',
  'received',
  'on hold',
  'in progress',
  'shipped',
  'ready for pickup',
  'delivered',
  'cancelled',
];

const SHIPPINGCODESBYID = {};
SHIPPINGCODES.forEach(c => {
  SHIPPINGCODESBYID[c.id] = c.name;
});
export {STATUSES, SHIPPINGCODES, SHIPPINGCODESBYID};
