/** @format */

import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Boxes from './addbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: 600,
    position: 'relative',
    color: theme.palette.text.secondary,
    '& iframe': {
      border: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      padding: 10,
    },
  },
  shippingButton: {
    minHeight: 40,
    marginRight: 10,
  },
}));

export default function Shipment({zebraPrinter}) {
  const [order, setOrder] = useState({});
  const [boxes, setBoxes] = useState([]);
  const [sendBoxes, setSendBoxes] = useState(false);
  const [disableShip, setDisableShip] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const classes = useStyles();
  const saturday = false;

  const updateBoxes = boxes => {
    setBoxes(boxes);
    setSendBoxes(true);
  };

  const shipPackage = e => {
    setDisableShip(true);
    let url = `/api/shipping/order/${id}?saturday=${saturday}`;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(sendBoxes ? boxes : {}),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        printShippingLabel(resp);
        setBoxes([]);
        setSendBoxes(false);
        setOpen(false);
        setDisableShip(false);
        setId('');
        // open dialog
      })
      .catch(e => {
        console.error(e);
        setDisableShip(false);
        // setGrowl({type: 'error', msg: e.message, show: true});
      });
  };

  const printShippingLabel = labels => {
    labels.forEach(t => {
      if (t.encodedLabel) {
        zebraPrinter.send(
          atob(t.encodedLabel),
          () => {},
          e => {
            console.error(e);
          }
        );
      } else {
        zebraPrinter.convertAndSendFile(
          `/api/shipping/zpl?url=${t.label}&type=.zpl`,
          () => {},
          e => {
            console.error(e);
          }
        );
      }
    });
  };

  const getOrder = () => {
    let url = '/api/sales/' + id;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        // open dialog
        setOrder(resp);
      })
      .catch(e => {});
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      getOrder();
      getBoxesByOrder();
    }
  };

  const getBoxesByOrder = () => {
    let url = `/api/shipping/order/${id}/boxes`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        setBoxes(resp);
        setOpen(true);
        // open dialog
      })
      .catch(e => {
        console.error(e);
        // setGrowl({type: 'error', msg: e.message, show: true});
      });
  };

  return (
    <Grid container spacing={3} style={{minHeight: 600}}>
      <Grid item xs={6} style={{marginTop: 200}}>
        <TextField
          id="standard-basic"
          value={id}
          onChange={e => {
            setId(e.target.value);
          }}
          label="Order Number"
          autoFocus={true}
          onKeyDown={handleKeyDown}
        />{' '}
        <Button
          color="primary"
          title="Print Shipping Label"
          variant="contained"
          className={classes.shippingButton}
          onClick={() => {
            getOrder();
            getBoxesByOrder();
          }}
          style={{
            color: 'white',
            backgroundColor: 'green',
            alignSelf: 'flex-end',
          }}
          startIcon={<i className="fab fa-fedex fa-2x"></i>}>
          Ship
        </Button>
      </Grid>
      <Grid item xs={6}></Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth={'md'}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Package Shipment</DialogTitle>
        <DialogContent>
          {order.shipping && (
            <div style={{fonSize: '2rem', margin: '10px 0 30px 0'}}>
              <Link to={`/order/${id}`}>{id}</Link>
              <br />
              {order.shipping.name}
              <br />
              {order.shipping.address} {order.shipping.address2}{' '}
              {order.shipping.state} {order.shipping.zipCode}
            </div>
          )}
          <Boxes rows={boxes} onChange={updateBoxes} />
        </DialogContent>
        <DialogActions style={{justifyContent: 'center'}}>
          <Button
            onClick={() => {
              setBoxes([]);
              setOpen(false);
            }}
            style={{position: 'absolute', right: 10}}
            color="primary">
            Cancel
          </Button>
          <Button
            onClick={shipPackage}
            style={{backgroundColor: 'green', color: 'white', width: 200}}
            variant="contained"
            disabled={disableShip}
            autoFocus>
            Ship
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
