/** @format */

import React, {useState} from 'react';
// Material UI imports
import {withStyles} from '@material-ui/core/styles';
// Custom Component Imports
import ShippingInput, {ShippingSelect} from './shipping-input';
import {isValidEmailEvent} from '../../utils/inputValidation';
// Styling import
import ShippingStyles from './styles';
import {SHIPPINGCODES} from '../../constants';

let formatedShippingService = SHIPPINGCODES.map(c => {
  return {code: c.id, ...c};
});
const ShippingDetails = props => {
  const {classes, updateFunc = () => {}, showErrors = false} = props || {};
  const [customerInfo, setCustomerInfo] = useState({
    firstName: {value: '', isValid: false},
    lastName: {value: '', isValid: false},
    email: {value: '', isValid: false},
    country: {
      value: 'United States of America',
      code: 'US',
      isValid: true,
    },
    address: {value: '', isValid: false},
    address2: {value: '', isValid: false},
    zipCode: {value: '', isValid: false},
    city: {value: '', isValid: true},
    phone: {value: '', isValid: false},
    note: {value: '', isValid: true},
    serviceCode: {code: 1, value: 'fedex priority overnight', isValid: true},
  });
  // TODO
  // Make API call to get valid shipping countries
  const countries = [
    {name: 'United States of America', code: 'US'},
    {name: 'Canada', code: 'CA'},
  ];

  const handleChange = event => {
    event.preventDefault();
    const dataType = event.target.id.split('-')[1];
    let newValue = event.target.value;
    const newCustomerInfo = {...customerInfo};
    if (dataType === 'phone') {
      newValue = formatPhoneNum(newValue);
    }
    newCustomerInfo[dataType].value = newValue;

    const trimVal = newValue.replaceAll(' ', '');
    let valid = false;
    switch (dataType) {
      case 'email':
        valid = isValidEmailEvent(event);
        break;
      case 'firstName':
      case 'lastName':
        valid = trimVal.length >= 2;
        break;
      case 'zipCode':
        if (customerInfo.country.code === 'US') {
          valid = trimVal.length >= 5 && isOnlyNumbers(newValue);
        } else if (customerInfo.country.code === 'CA') {
          let regex = new RegExp(
            /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
          );
          valid = regex.test(newValue);
        }
        break;
      case 'note':
        valid = true;
        break;
      case 'address2':
        var pattern = new RegExp(
          '\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b',
          'i'
        );
        if (pattern.test(newValue)) {
          newCustomerInfo['serviceCode'] = {
            value: 'fedex smartpost',
            code: 95,
            isValid: true,
          };
        }
        break;
      case 'phone':
        const numsOnly = newValue.replaceAll(/[^\d]/g, '');
        valid = numsOnly.length === 10;
        break;
      default:
        valid = newValue !== '';
        break;
    }
    newCustomerInfo[dataType].isValid = valid;

    // console.log(dataType,newValue,newCustomerInfo)
    setCustomerInfo(newCustomerInfo);
    // handleValidation(event);
    // updateFunc(newValue, dataType);
  };

  const formatPhoneNum = text => {
    if (!text) return text; // empty/null check
    const numsOnly = text.replaceAll(/[^\d]/g, '');
    const numCount = numsOnly.length;
    if (numCount > 0) {
      if (numCount < 4) {
        return numsOnly;
      } else if (numCount < 7) {
        return `(${numsOnly.slice(0, 3)}) ${numsOnly.slice(3)}`;
      } else {
        return `(${numsOnly.slice(0, 3)}) ${numsOnly.slice(
          3,
          6
        )}-${numsOnly.slice(6, 10)}`;
      }
    }
    return numsOnly;
  };

  const isOnlyNumbers = text => {
    const numsOnly = /^[0-9]*$/g;
    return numsOnly.test(text);
  };

  const handleValidation = event => {
    const dataType = event.target.id.split('-')[1];
    const value = event.target.value;
    console.log(dataType, event.target, value);
    const trimVal = value.replaceAll(' ', '');
    let valid = false;
    switch (dataType) {
      case 'email':
        valid = isValidEmailEvent(event);
        break;
      case 'firstName':
      case 'lastName':
        valid = trimVal.length >= 2;
        break;
      case 'zipCode':
        // valid = trimVal.length >= 5 && isOnlyNumbers(value);
        if (customerInfo.country.code === 'US') {
          valid = trimVal.length >= 5 && isOnlyNumbers(value);
        } else if (customerInfo.country.code === 'CA') {
          let regex = new RegExp(
            /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
          );
          valid = regex.test(value);
        }
        break;
      case 'note':
        valid = true;
        break;
      case 'phone':
        const numsOnly = value.replaceAll(/[^\d]/g, '');
        valid = numsOnly.length === 10;
        break;
      default:
        valid = value !== '';
        break;
    }
    const newCustomerInfo = customerInfo;
    newCustomerInfo[dataType].isValid = valid;
    setCustomerInfo(newCustomerInfo);
    updateFunc(newCustomerInfo);
  };

  const calcShowErrs = valName => {
    const isEmptyVal = customerInfo[valName].value === '';
    return (
      (isEmptyVal && showErrors) ||
      (!isEmptyVal && !customerInfo[valName].isValid)
    );
  };

  return (
    <div className={classes.wrapper} key="shipping-wrapper">
      <ShippingSelect
        addedClasses="inp"
        required
        label="Shipping Service"
        id="inp-serviceCode"
        value={customerInfo.serviceCode}
        passedOnChange={e => {
          const newValue = e.target.value;
          const newCustomerInfo = {...customerInfo};
          newCustomerInfo['serviceCode'] = {
            value: formatedShippingService.find(c => c.code === newValue).name,
            code: newValue,
            isValid: true,
          };
          setCustomerInfo(newCustomerInfo);
        }}
        values={formatedShippingService}
        isError={showErrors && !customerInfo.serviceCode.isValid}
        errorText="This field is required"
      />
      <div className={classes.nameFields + ' inp'} key="name-fields">
        <ShippingInput
          label="First Name"
          id="inp-firstName"
          required
          value={customerInfo.firstName.value}
          onChange={handleChange}
          onBlur={handleValidation}
          isError={calcShowErrs('firstName')}
          errorText="This field is required"
        />
        <ShippingInput
          required
          label="Last Name"
          id="inp-lastName"
          value={customerInfo.lastName.value}
          onChange={handleChange}
          onBlur={handleValidation}
          isError={calcShowErrs('lastName')}
          errorText="This field is required"
        />
      </div>
      <ShippingInput
        addedClasses="inp"
        required
        type="email"
        label="Email Address"
        id="inp-email"
        value={customerInfo.email.value}
        onChange={handleChange}
        onBlur={handleValidation}
        isError={calcShowErrs('email')}
        errorText={
          customerInfo.email.value === ''
            ? 'This field is required'
            : 'Invalid email address'
        }
      />
      <ShippingSelect
        addedClasses="inp"
        required
        label="Country"
        id="inp-country"
        value={customerInfo.country}
        passedOnChange={e => {
          const newValue = e.target.value;
          const newCustomerInfo = {...customerInfo};
          newCustomerInfo['country'] = {
            value: countries.find(c => c.code === newValue).name,
            code: newValue,
            isValid: true,
          };
          setCustomerInfo(newCustomerInfo);
        }}
        values={[...countries]}
        isError={showErrors && !customerInfo.country.isValid}
        errorText="This field is required"
      />
      <ShippingInput
        addedClasses="inp"
        required
        label="Street Address"
        id="inp-address"
        value={customerInfo.address.value}
        onChange={handleChange}
        onBlur={handleValidation}
        isError={calcShowErrs('address')}
        errorText="This field is required"
      />
      <ShippingInput
        addedClasses="inp"
        label="Street Address 2"
        id="inp-address2"
        value={customerInfo.address2.value}
        onChange={handleChange}
        onBlur={handleValidation}
        isError={calcShowErrs('address')}
        errorText=""
      />
      <ShippingInput
        required
        type="text"
        addedClasses="inp"
        label="Zip Code"
        id="inp-zipCode"
        value={customerInfo.zipCode.value}
        onChange={handleChange}
        onBlur={handleValidation}
        isError={calcShowErrs('zipCode')}
        errorText={
          customerInfo.zipCode.value === ''
            ? 'This field is required'
            : 'Invalid Zip Code'
        }
      />
      <ShippingInput
        addedClasses="inp"
        label="City"
        id="inp-city"
        type="note"
        value={customerInfo.city.value}
        onChange={handleChange}
        onBlur={handleValidation}
        isError={
          // Figure something out for this
          false
        }
      />
      <ShippingInput
        addedClasses="inp"
        required
        type="tel"
        label="Phone Number"
        id="inp-phone"
        value={customerInfo.phone.value}
        onChange={handleChange}
        onBlur={handleValidation}
        isError={calcShowErrs('phone')}
        errorText={
          customerInfo.phone.value === ''
            ? 'This field is required'
            : 'Invalid phone number'
        }
      />
      <ShippingInput
        addedClasses="inp"
        multiline
        rows={4}
        label="Order Note"
        id="inp-note"
        value={customerInfo.note.value}
        onChange={handleChange}
        onBlur={handleValidation}
      />
    </div>
  );
};

export default withStyles(ShippingStyles)(ShippingDetails);
