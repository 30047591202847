/** @format */

import React from 'react';
import './print.css';

import Grid from '@material-ui/core/Grid';
import QRCode from 'qrcode.react';

export default function Label(props) {
  const {location} = props;
  const {id, material, color, diameter, qty = 1} = location.qs;
  return (
    <>
      {Array(Number(qty)).fill(
        <div
          style={{
            width: '3.5in',
            height: '1.1in',
            margin: 0,
            pageBreakAfter: 'always',
          }}>
          <Grid
            container
            spacing={2}
            style={{margin: 2, maxWidth: '100%', maxheight: '100%'}}>
            <Grid item xs={6}>
              <QRCode
                value={`https://printbed.com/material/data/${id}`}
                size={86}
              />
            </Grid>
            <Grid item xs={6} style={{textAlign: 'left'}}>
              Type: {material.toUpperCase()} <br />
              Color: {color} <br />
              Diameter: {diameter} <br />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
