/** @format */

import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/title';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import Button from '@material-ui/core/Button';
import SortableList from '../../components/reorderlist';
export default function Item({history, addAlert}) {
  // const classes = useStyles();
  const [items, setItems] = useState([]);
  const [sortedItems, setSortedItems] = useState(null);

  const getInventory = () => {
    let query = new URLSearchParams();
    query.set('tags.showOnHome', '"true"');
    let url = `/api/items/?skip=0&limit=500&all=true&q=${encodeURIComponent(
      query.toString()
    )}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        setItems(
          resp
            .sort((a, b) => {
              return a.displayIndex - b.displayIndex;
            })
            .map(item => {
              return {id: item.id, text: item.name};
            })
        );
      })
      .catch(e => {});
  };

  useEffect(() => {
    getInventory();
    // eslint-disable-next-line
  }, []);

  const saveSorted = () => {
    let url = `/api/items`;
    fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sortedItems),
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(resp => {
        //open dialog
        // console.log(resp);
        addAlert(`Display order has been saved.`, 'success');
      })
      .catch(e => {
        addAlert(e.message, 'error');
      });
  };

  return (
    <React.Fragment>
      <Title>Front Page Order</Title>
      {sortedItems && <Button onClick={saveSorted}>Save</Button>}
      {items.length > 0 && (
        <SortableList items={items} onChange={setSortedItems} />
      )}
    </React.Fragment>
  );
}
