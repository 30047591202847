/** @format */

import React from 'react';
import Cookies from 'universal-cookie';
import {jwtDecode} from 'jwt-decode';

const JWT = props => {
  const {history, match} = props;
  const {params} = match;
  if (params.jwt) {
    const {userId} = jwtDecode(params.jwt);
    const cookies = new Cookies();
    cookies.set('JWT', params.jwt, {path: '/'});
    fetch('/api/user/' + userId)
      .then(resp => resp.json())
      .then(user => {
        if (user.role === 'admin') {
          const data = {
            userId,
            jwt: params.jwt,
            loggedIn: true,
            allowAdmin: true,
          };
          localStorage.setItem('user', JSON.stringify(data));
          window.location.href = '/';
        } else {
          throw new Error('not an admin.');
        }
      })
      .catch(e => {
        cookies.remove('JWT', {path: '/'});
        window.location.href = '/';
      });
  } else {
    history.replace('/');
  }

  return <></>;
};

export default JWT;
