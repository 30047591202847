/** @format */

import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Title from '../../components/title';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import moment from 'moment';

import TablePaginationActions from '../../components/common/TablePaginationActions';

const styles = makeStyles(theme => ({
  gridRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridGap: 10,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',

    '& > * ': {
      padding: '6px 16px',
      textAlign: 'left',
    },
  },
  titleRow: {
    fontWeight: 'bold',
  },
  detailRow: {
    background: '#f5f6f7',
    textAlign: 'left',
    lineHeight: 1.5,
    padding: '10px 10px 10px 100px',
  },
  userCell: {
    fontWeight: 'bold',
    color: 'blue',
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: 5,
    alignItems: 'center',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  orderLink: {
    color: 'blue',
  },
  textRight: {
    textAlign: 'right',
  },
}));

export default function Shippings(props) {
  const [shippings, setShippings] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('asc');
  const [count, setCount] = useState(0);
  const classes = styles();

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(Number(e.target.value));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getShippings = (page, rowsPerPage) => {
    let url = `/api/shipping/all?skip=${
      page * rowsPerPage
    }&limit=${rowsPerPage}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        const {count, shippings} = resp;
        setCount(count);
        shippings.forEach(x => {
          x.orderCount = x.orders.length;
          x.date = moment(x._system.modified).format('MMM DD, YYYY');
          x.showDetail = false;
        });
        setShippings(sortShippings(shippings, orderBy, order));
      })
      .catch(e => {
        console.error(e);
      });
  };

  const sortShippings = (shippings, key, order) => {
    return shippings.sort(compare(key, order));
  };

  const compare = (key = 'userId', order = 'desc') => (a, b) => {
    let genreA = a[key];
    let genreB = b[key];

    // Use toUpperCase() to ignore character casing
    if (typeof genreA === 'string') {
      genreA = genreA.toUpperCase();
      genreB = genreB.toUpperCase();
    }

    if (genreA === genreB) {
      genreA = a.userId.toUpperCase();
      genreB = b.userId.toUpperCase();
    }

    let comparison = 0;
    if (order === 'desc') {
      if (genreA > genreB) {
        comparison = 1;
      } else if (genreA < genreB) {
        comparison = -1;
      }
    } else {
      if (genreA < genreB) {
        comparison = 1;
      } else if (genreA > genreB) {
        comparison = -1;
      }
    }
    return comparison;
  };

  const sort = (header, order) => (e, property) => {
    let newOrder = 'desc';
    if (order === 'desc') {
      newOrder = 'asc';
    }
    setOrder(newOrder);
    setOrderBy(header);
    setShippings(sortShippings(shippings, header, newOrder));
  };

  useEffect(() => {
    getShippings(page, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const toggleDetail = shipping => {
    shipping.showDetail = !shipping.showDetail;
    setShippings([...shippings]);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Title>Shippings</Title>
          <div className={`${classes.gridRow} ${classes.titleRow}`}>
            <TableSortLabel
              active={orderBy === 'userId'}
              direction={order}
              onClick={sort('userId', order)}>
              User Id
            </TableSortLabel>
            <TableSortLabel
              active={orderBy === 'orderCount'}
              direction={order}
              onClick={sort('orderCount', order)}>
              Order
            </TableSortLabel>
            <TableSortLabel
              active={orderBy === 'status'}
              direction={order}
              onClick={sort('status', order)}>
              Status
            </TableSortLabel>
            <div>Date</div>
            <div className={classes.textRight}>Label</div>
          </div>

          {shippings.map(row => (
            <div key={row.id}>
              <div className={classes.gridRow}>
                <div
                  onClick={() => toggleDetail(row)}
                  className={classes.userCell}>
                  {row.showDetail ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                  {row.userId}
                </div>
                <div>{row.orderCount}</div>
                <div>{row.status}</div>
                <div>{row.date}</div>
                <div className={classes.textRight}>${row.totalPrice}</div>
              </div>
              {row.showDetail && (
                <div className={classes.detailRow}>
                  {row.orders.map(order => (
                    <div key={order}>
                      <Link
                        className={classes.orderLink}
                        to={`/order/${order}`}>
                        Order # {order}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          <div className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
