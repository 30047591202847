/** @format */

import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import CreateLabel from './createLabel';
import TabPanel from '../../components/tabpanel';
import Shipment from './shipment';
import Boxes from './boxes';
import ShippingReport from '../shippingreport';
import ShippingByOrder from './byorder';
const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    textAlign: 'left',
  },
  chips: {
    margin: theme.spacing(0.5),
    display: 'inline-flex',
  },
}));

export default function Orders(props) {
  const {history, location} = props;
  const {qs} = location;
  const classes = useStyles();
  const [value, setValue] = useState(
    location.qs.tabId ? Number(location.qs.tabId) : 0
  );

  const changeTab = (e, value) => {
    history.replace('/shipping?tabId=' + value);
  };

  useEffect(() => {
    if (qs.tabId && Number(qs.tabId) !== value) {
      setValue(Number(qs.tabId));
    }
    // eslint-disable-next-line
  }, [qs]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={4} className={classes.paper}></Grid>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={changeTab}
            indicatorColor="primary"
            textColor="primary">
            <Tab label="Ship by Order" />
            <Tab label="Ship" />
            <Tab label="Boxes" />
            <Tab label="Shipping Report" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <ShippingByOrder {...props} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Shipment {...props} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Boxes {...props} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ShippingReport />
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  );
}
