/** @format */

import React, {useEffect, useState} from 'react';
import {Container, Draggable} from 'react-smooth-dnd';
import {arrayMoveImmutable} from 'array-move';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DragHandleIcon from '@material-ui/icons/DragHandle';

const SortableList = ({items = [], onChange = () => {}}) => {
  const [sortedItems, setSortedItems] = useState(items);

  const onDrop = ({removedIndex, addedIndex}) => {
    setSortedItems(arrayMoveImmutable(sortedItems, removedIndex, addedIndex));
  };

  useEffect(() => {
    onChange(sortedItems);
  }, [sortedItems]);

  return (
    <Container style={{maxWidth: '50%', align: 'center'}}>
      <List>
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={onDrop}>
          {sortedItems.map(({id, text}, idx) => (
            <Draggable key={id}>
              <ListItem
                style={{
                  backgroundColor: 'lightblue',
                  borderRadius: 5,
                  margin: '5px 0px',
                  cursor: 'pointer',
                }}
                className="drag-handle">
                <ListItemText primary={idx + 1 + ' ' + text} />
                <ListItemSecondaryAction>
                  <ListItemIcon
                    className="drag-handle"
                    style={{cursor: 'pointer'}}>
                    <DragHandleIcon />
                  </ListItemIcon>
                </ListItemSecondaryAction>
              </ListItem>
            </Draggable>
          ))}
        </Container>
      </List>
    </Container>
  );
};

export default SortableList;
