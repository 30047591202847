/** @format */

import React from 'react';
import {
  Legend,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import Title from '../title';

const getColor = color => {
  let tmp;
  switch (color) {
    case 'peanut butter':
    case 'peanutbutter':
    case 'peanut butter v2':
      tmp = '#cd9141';
      break;
    case 'chocolate':
    case 'chocolate v2':
      tmp = '#7B3F00';
      break;
    case 'natural':
      tmp = '#f0ece9';
      break;
    case 'white':
      tmp = '#f6f3f2';
      break;
    case 'mint':
      tmp = '#3EB489';
      break;
    case 'peach':
      tmp = '#FFDAB9';
      break;
    case 'sourapple':
    case 'sour apple v2':
      tmp = '#8db600';
      break;
    case 'fuchsia':
      tmp = '#ff00ff';
      break;
    case 'nacho cheese':
      tmp = '#FFCF6B';
      break;
    case 'midnight blue':
      tmp = '#191970';
      break;
    case 'Jungle Green':
      tmp = '#364815';
      break;
    case 'Black Cherry':
      tmp = '#590014';
      break;
    case 'lavender':
    case 'lavender v2':
      tmp = '#E6E6FA';
      break;
    case 'Charcoal':
    case 'charcoalgrey':
    case 'charcoal grey':
    case 'dark grey':
      tmp = '#323E42';
      break;
    case 'mini':
    case 'MINI':
      // tmp = '#818380';
      tmp = 'url(#miniGradient)';
      break;
    case 'mystery':
      // tmp = '#6A5ACD';
      tmp = 'url(#mysteryGradient)';
      break;
    case 'baja blue':
    case 'bajablue':
    case 'baja blue v2':
      tmp = '#5F6EAE';
      break;
    case 'cottencandypink':
    case 'cottoncandypink':
    case 'cotton candy pink':
      tmp = '#ff69b4';
      break;
    default:
      tmp = color;
  }
  return tmp;
};

export default function Chart({colorsTotals, title = 'Colors'}) {
  colorsTotals.sort((a, b) => b.count - a.count);
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <ResponsiveContainer>
        <PieChart width={730} height={250}>
          <defs>
            <linearGradient
              id={`miniGradient`}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%">
              <stop offset="0%" stopColor="rgba(255, 0, 0, 1)" />
              <stop offset="10%" stopColor="rgba(255, 154, 0, 1)" />
              <stop offset="20%" stopColor="rgba(208, 222, 33, 1)" />
              <stop offset="30%" stopColor="rgba(79, 220, 74, 1)" />
              <stop offset="40%" stopColor="rgba(63, 218, 216, 1)" />
              <stop offset="50%" stopColor="rgba(47, 201, 226, 1)" />
              <stop offset="60%" stopColor="rgba(28, 127, 238, 1)" />
              <stop offset="70%" stopColor="rgba(95, 21, 242, 1)" />
              <stop offset="80%" stopColor="rgba(186, 12, 248, 1)" />
              <stop offset="90%" stopColor="rgba(251, 7, 217, 1)" />
              <stop offset="100%" stopColor="rgba(255, 0, 0, 1)" />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id={`mysteryGradient`}>
              <stop offset="0%" stopColor="#9e54ed" />
              <stop offset="100%" stopColor="#5c4cb6" />
            </linearGradient>
          </defs>
          <Pie
            data={colorsTotals}
            dataKey="count"
            nameKey="color"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label>
            {colorsTotals.map((entry, index) => (
              <Cell key={index} fill={getColor(entry.color)} />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
