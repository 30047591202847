/** @format */

import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ConfirmDialog from '../../components/confirm';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '../../dialog';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '50%',
  },
  formControl2: {
    margin: theme.spacing(1),
    width: '25%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const localizer = momentLocalizer(moment);

export default function Schedule({setGrowl}) {
  const [myEvents, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({});
  const [eventSelected, setEventSelected] = useState(null);
  const classes = useStyles();
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const getEvents = () => {
    let month = getMonthStartEndWithRange(currentMonth);
    let url = `/api/events?start=${month.weekBeforeStart}&end=${month.weekAfterEnd}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        setEvents(resp);
        // open dialog
      })
      .catch(e => {
        console.error(e);
        // setGrowl({type: 'error', msg: e.message, show: true});
      });
  };

  const handleNavigate = (date, view) => {
    // Handle the change in month
    console.log('Navigated to date:', date);
    console.log('Current view:', view);
    setCurrentMonth(date);
  };

  useEffect(() => {
    getEvents();
  }, [currentMonth]);

  const addEvent = () => {
    setEvents(prev => [...prev, newEvent]);

    let url = '/api/event/';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(newEvent),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        // open dialog

        setNewEvent({});
        setOpen(false);
      })
      .catch(e => {
        setNewEvent({});
        setOpen(false);
        setGrowl({type: 'error', msg: e.message, show: true});
      });
  };

  const handleSelectEvent = useCallback(event => {
    setEventSelected(event);
  }, []);

  const handleChange = name => e => {
    let tmp = {...newEvent};
    if (e.target.type === 'checkbox') {
      tmp[name] = e.target.checked;
    } else {
      tmp[name] = e.target.value;
    }
    setNewEvent(tmp);
  };

  const EventForm = () => {
    return (
      <div>
        <FormControl className={classes.formControl}>
          <TextField
            fullWidth={true}
            margin="dense"
            id="title"
            label="Title"
            name="title"
            value={newEvent.title || ''}
            onChange={handleChange('title')}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            fullWidth={true}
            margin="dense"
            id="notes"
            label="Notes"
            name="notes"
            multiline={true}
            minRows={3}
            value={newEvent.notes || ''}
            onChange={handleChange('notes')}
          />
        </FormControl>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              value="remember"
              name="allDay"
              color="primary"
              checked={newEvent.allDay}
              onChange={handleChange('allDay')}
            />
          }
          label="All Day"
        />
        <br />
        {newEvent.allDay === false && (
          <>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth={true}
                margin="dense"
                id="startTime"
                label="Start Time"
                name="startTime"
                type="time"
                value={newEvent.startTime || '10:00'}
                onChange={handleChange('startTime')}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth={true}
                margin="dense"
                id="endTime"
                label="End Time"
                name="endTime"
                type="time"
                value={newEvent.endTime || '11:00'}
                onChange={handleChange('endTime')}
              />
            </FormControl>
          </>
        )}
      </div>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {eventSelected && (
          <Dialog
            open={eventSelected}
            title={eventSelected.title || ''}
            content={
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <i>{new Date(eventSelected.start).toDateString()}</i>
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {eventSelected.notes}
                </DialogContentText>
              </DialogContent>
            }
            onClose={() => {
              setEventSelected(null);
            }}
          />
        )}
        <ConfirmDialog
          open={open}
          title="Calendar Entry"
          content={EventForm()}
          onConfirm={addEvent}
          onCancel={() => {
            setNewEvent({});
            setOpen(false);
          }}
        />
        <Calendar
          localizer={localizer}
          events={myEvents}
          startAccessor="start"
          endAccessor="end"
          onNavigate={handleNavigate}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={({start, end}) => {
            setNewEvent({start, end, allDay: true});
            setOpen(true);
          }}
          selectable
          // scrollToTime={scrollToTime}
          style={{height: 500}}
        />
      </Grid>
    </Grid>
  );
}

function getMonthStartEndWithRange(currentDate = new Date()) {
  console.log(currentDate);
  // const currentDate = new Date(date); // Get current date
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Create a new Date object for the start of the month
  const monthStart = new Date(currentYear, currentMonth, 1, 0, 0, 0, 0);

  // Create a new Date object for the end of the month
  const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1; // If current month is December, next month is January of next year
  const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear; // If current month is December, next year is next year
  const monthEnd = new Date(nextYear, nextMonth, 1, 0, 0, 0, 0);

  // Create a new Date object for 7 days before the start of the month
  const weekBeforeStart = new Date(monthStart);
  weekBeforeStart.setDate(monthStart.getDate() - 7);

  // Create a new Date object for 7 days after the end of the month
  const weekAfterEnd = new Date(monthEnd);
  weekAfterEnd.setDate(monthEnd.getDate() + 7);

  return {
    start: monthStart,
    end: monthEnd,
    weekBeforeStart: weekBeforeStart,
    weekAfterEnd: weekAfterEnd,
  };
}
