/** @format */

import React, {useState, useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
const daysInWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  button: {
    // margin: theme.spacing(1),
  },
  save: {
    marginTop: theme.spacing(3),
  },
  preview: {
    maxHeight: 75,
  },
  label: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    zIndex: 1000,
    opacity: 0,
    visibility: 'hidden',
  },
  textField: {
    marginTop: 0,
  },
  timeField: {
    marginRight: theme.spacing(3),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

export default function Profile(props) {
  const {user, userId} = props;
  const [fetching, setFetching] = useState(true);
  const [notifications, setNotifications] = useState({});
  const [update, setUpdate] = useState(false);
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    let url = '/api/sms/' + userId;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        // open dialog
        setNotifications(resp);
        setFetching(false);
      })
      .catch(e => {});
    // eslint-disable-next-line
  }, []);

  const saveSettings = () => {
    let url = '/api/sms/' + userId;
    fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(notifications),
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        // open dialog
        setNotifications(resp);
        setUpdate(false);
      })
      .catch(e => {});
  };

  const handleTimeChange = e => {
    let value = e.target.value;
    let name = e.target.name;
    let tmp = {...notifications};
    let [hour, min] = value.split(':');
    let time = Number(hour) + Number(min) / 60;
    tmp[name] = time;
    setNotifications(tmp);
    setUpdate(true);
  };

  const handleChange = e => {
    let value = e.target.checked;
    let name = e.target.name;
    let tmp = {...notifications};
    if (name === 'days') {
      if (value === true) {
        notifications.days.push(Number(e.target.value));
      } else {
        const index = notifications.days.indexOf(Number(e.target.value));
        if (index > -1) {
          notifications.days.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    } else {
      tmp[name] = value;
    }
    setNotifications(tmp);
    setUpdate(true);
  };

  const formatTime = number => {
    let hour = Math.floor(number);
    let decpart = number - hour;
    let min = 1 / 60;
    decpart = min * Math.round(decpart / min);
    let minute = Math.floor(decpart * 60) + '';
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    hour = hour.toString();
    if (hour.length < 2) {
      hour = '0' + hour;
    }
    let time = hour + ':' + minute;
    return time;
  };

  return (
    <>
      {!user ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={5} lg={4}>
            <Paper className={fixedHeightPaper}>
              <div style={{padding: 40, textAlign: 'left'}}>
                <Avatar
                  alt={user.displayName}
                  src={user.avatar}
                  className={classes.large}
                />
                <br />
                <TextField
                  id="standard-name"
                  label="Name"
                  className={classes.textField}
                  value={user.displayName || user.fname + ' ' + user.lname}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="standard-name"
                  label="Registered"
                  className={classes.textField}
                  value={new Date(user.ts).toLocaleString()}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={false} md={false} lg={2}></Grid>
          <Grid item xs={12} md={6} lg={12}>
            {!fetching && (
              <Paper className={fixedHeightPaper}>
                <div style={{padding: 40, textAlign: 'left'}}>
                  <h2>Notifications</h2>
                  <TextField
                    id="standard-name"
                    label="SMS"
                    className={classes.textField}
                    value={notifications.ph || ''}
                    onChange={e => {
                      let tmp = {...notifications};
                      let value = e.target.value;
                      if (!value.startsWith('+1')) {
                        let str = '+';
                        if (!value.startsWith('1')) {
                          str += '1';
                        }
                        value = str + value;
                      }
                      tmp.ph = value;
                      setNotifications(tmp);
                      setUpdate(true);
                    }}
                    margin="normal"
                  />
                  <h3>Types</h3>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notifications.sales}
                          onChange={handleChange}
                          name="sales"
                        />
                      }
                      label="Sales"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notifications.localPickup}
                          onChange={handleChange}
                          name="localPickup"
                          color="primary"
                        />
                      }
                      label="Local Pickup"
                    />
                  </FormGroup>
                  <h3>Day of the week</h3>
                  <FormGroup row>
                    {[0, 1, 2, 3, 4, 5, 6].map(day => {
                      return (
                        <FormControlLabel
                          key={day}
                          control={
                            <Checkbox
                              checked={notifications.days.indexOf(day) >= 0}
                              onChange={handleChange}
                              name={`days`}
                              value={day}
                            />
                          }
                          label={daysInWeek[day]}
                        />
                      );
                    })}
                  </FormGroup>
                  <h3>Times</h3>
                  <FormGroup row>
                    <TextField
                      id="time"
                      label="Start Time"
                      type="time"
                      name="from"
                      value={formatTime(notifications.from)}
                      onChange={handleTimeChange}
                      className={classes.timeField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />

                    <TextField
                      id="time"
                      label="End Time"
                      type="time"
                      name="to"
                      value={formatTime(notifications.to)}
                      onChange={handleTimeChange}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </FormGroup>
                  {update && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={saveSettings}
                      className={classes.save}
                      startIcon={<SaveIcon />}>
                      Save
                    </Button>
                  )}
                </div>
              </Paper>
            )}
          </Grid>
          {/* Recent Orders */}
        </Grid>
      )}
    </>
  );
}
