/** @format */

import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TabPanel from '../../components/tabpanel';
import CheckIn from './checkin';
import Items from './inventory';
import Use from './use';
import Report from './report';
import FrontPage from './frontpage';
import {updateURLParams} from '../../utils/common';

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    textAlign: 'left',
  },
  chips: {
    margin: theme.spacing(0.5),
    display: 'inline-flex',
  },
}));

export default function Orders(props) {
  const {history, location} = props;
  const updateUrl = updateURLParams(location, history);
  const {qs} = location;
  const classes = useStyles();

  const changeTab = (e, value) => {
    updateUrl({tabId: value});
  };

  useEffect(() => {
    if (!qs.tabId) {
      updateUrl({tabId: 0});
    }
    // eslint-disable-next-line
  }, []);

  let tabId = Number(qs.tabId) || 0;

  return (
    <Grid container spacing={3}>
      <Grid item xs={4} className={classes.paper}></Grid>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <Tabs
            value={tabId}
            onChange={changeTab}
            indicatorColor="primary"
            textColor="primary"
            centered>
            <Tab label="Items" />
            <Tab label="Check In" />
            <Tab label="Use" />
            <Tab label="Report" />
            <Tab label="Front Page Order" />
          </Tabs>
          <TabPanel value={tabId} index={0}>
            <Items {...props} />
          </TabPanel>
          <TabPanel value={tabId} index={1}>
            <CheckIn {...props} />
          </TabPanel>
          <TabPanel value={tabId} index={2}>
            <Use {...props} />
          </TabPanel>
          <TabPanel value={tabId} index={3}>
            <Report {...props} />
          </TabPanel>
          <TabPanel value={tabId} index={4}>
            <FrontPage {...props} />
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  );
}
