/** @format */

const updateURLParams = (location, history) => opts => {
  let url = location.pathname;
  let qs = new URLSearchParams(location.search);
  Object.keys(opts).forEach(key => {
    let value = opts[key];
    qs.set(key, value);
    if (value === '') {
      qs.delete(key);
    }
  });
  history.push(url + '?' + qs.toString());
};

export {updateURLParams};
