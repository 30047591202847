/** @format */

import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/title';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Avatar from '@material-ui/core/Avatar';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import AddHolliday from './addHolliday';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  actions: {
    minWidth: 100,
  },
}));

export default function Holidays({addAlert}) {
  const classes = useStyles();
  const [holidays, setHolidays] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState();
  const [openItem, setOpenItem] = useState(false);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(Number(e.target.value));
  };
  const openAddItem = () => {
    setOpenItem(true);
  };

  const handleItemClose = data => {
    setOpenItem(false);
    getHolidays();
  };

  useEffect(() => {
    getHolidays();
  }, [page, rowsPerPage]);

  const getHolidays = () => {
    let url = `/api/hollidays/?skip=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&all=true`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        //open dialog
        // console.log(resp);
        setCount(resp.count);
        setHolidays(resp.resp);
      })
      .catch(e => {});
  };

  const editItem = item => {
    setSelected(item);
    setOpenItem(true);
  };

  const deleteItem = item => {
    if (item.id) {
      let cont = window.confirm('Are you sure you want to delete this notice?');
      if (cont === true) {
        let url = `/api/holliday/${item.id}`;
        fetch(url, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
          .then(function (res) {
            return res.json();
          })
          .then(resp => {
            //open dialog
            getHolidays();
          })
          .catch(e => {});
      }
    }
  };

  return (
    <React.Fragment>
      <Title>Hollidays</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Notice</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {holidays.map(row => (
            <TableRow key={row.id}>
              <TableCell>
                <Avatar
                  alt={row.id}
                  src={'data:image/png;base64,' + row.image}
                  className={classes.large}
                />
              </TableCell>
              <TableCell>{row.note}</TableCell>
              <TableCell>{new Date(row.start).toLocaleString()}</TableCell>
              <TableCell>{new Date(row.end).toLocaleString()}</TableCell>
              <TableCell align="right" className={classes.actions}>
                <IconButton
                  color="primary"
                  title="Edit"
                  size="small"
                  className={classes.button}
                  onClick={() => {
                    editItem(row);
                  }}>
                  <Icon>create</Icon>
                </IconButton>
                <IconButton
                  color="secondary"
                  title="Delete"
                  size="small"
                  className={classes.button}
                  onClick={() => {
                    deleteItem(row);
                  }}>
                  <Icon>delete</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <AddHolliday
        openItem={openItem}
        addAlert={addAlert}
        selected={selected}
        handleItemClose={handleItemClose}
      />
      <Fab
        color="primary"
        onClick={openAddItem}
        aria-label="add"
        style={{position: 'fixed', right: 10, bottom: 10}}>
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
}
