/** @format */

import React, {useState, useEffect} from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/title';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import BusinessIcon from '@material-ui/icons/Business';
import AdjustIcon from '@material-ui/icons/Adjust';
import {updateURLParams} from '../../utils/common';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

export default function ShippingReport(props) {
  const today = new Date().toISOString().substring(0, 10);
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow = tomorrow.toISOString().substring(0, 10);

  let location = useLocation();
  const {qs} = location;
  const history = useHistory();
  const updateUrl = updateURLParams(location, history);
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const handleChangePage = (e, page) => {
    updateUrl({page});
  };

  const handleChangeRowsPerPage = e => {
    updateUrl({limit: e.target.value});
  };

  useEffect(() => {
    if (location.search && location.search.indexOf('page') >= 0) {
      getOrders();
    } else {
      let defaults = {
        limit: 50,
        page: 0,
        startDate: today,
        endDate: tomorrow,
      };
      updateUrl(defaults);
    }
    //eslint-disable-next-line
  }, [location]);

  const getOrders = () => {
    let url = localStorage.getItem('url') || '';
    let sort = '';
    if (qs.limit) {
      let statuses = 'all';

      if (qs.openOrders === 'true') {
        statuses = 'received,shipped,in progress,ready for pickup';
        sort = '&sort=status&dir=1';
      }

      url = `/api/sales/?skip=${qs.page * qs.limit}&limit=${
        qs.limit
      }&all=true&status=${statuses}${sort}&start=${qs.startDate}&end=${
        qs.endDate
      }`;
      if (qs.search) {
        url += `&search=${qs.search}`;
      }
      localStorage.setItem('url', url);
    }

    if (!url) {
      return;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        if (resp && resp.orders) {
          setCount(resp.count);
          setOrders(resp.orders);
        }
      })
      .catch(e => {});
  };

  return (
    <React.Fragment>
      <Title>Shipping Report by Order</Title>
      <div style={{flex: '0 0 50%', textAlign: 'left'}}>
        <FormControl>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            value={qs.startDate || ''}
            onChange={e => {
              updateUrl({startDate: e.target.value});
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FormControl>
          <TextField
            id="date"
            label="End Date"
            type="date"
            value={qs.endDate || ''}
            onChange={e => {
              updateUrl({endDate: e.target.value});
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="none" size="small"></TableCell>
            <TableCell>Order#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Tracking Number</TableCell>
            <TableCell>Shipping Collected</TableCell>
            <TableCell>Shipping Cost</TableCell>
            <TableCell>Tax Collected</TableCell>
            <TableCell align="right">Sale Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(row => (
            <TableRow
              key={row.id}
              style={{
                backgroundColor:
                  row.status === 'shipped'
                    ? 'lightgrey'
                    : row.status === 'delivered'
                    ? '#7393B3'
                    : 'none',
              }}>
              <TableCell
                style={{verticalAlign: 'bottom'}}
                align="center"
                padding="none"
                size="small">
                {row.shipping.bulk ? (
                  <BusinessIcon style={{color: 'rgba(0, 0, 0, 0.87)'}} />
                ) : (
                  <AdjustIcon style={{color: 'rgba(0, 0, 0, 0.87)'}} />
                )}
              </TableCell>
              <TableCell>
                <Link to={`/order/${row.id}`}>{row.shortId}</Link>
              </TableCell>
              <TableCell>
                {row._system.createdBy.indexOf('cus_') === -1 &&
                row._system.createdBy.indexOf('-') === -1 &&
                row.payment.refType.toLowerCase() !== 'promo' ? (
                  <Link to={`/user/${row._system.createdBy}`}>
                    {row.shipping.name ||
                      row.shipping.firstName + ' ' + row.shipping.lastName}
                  </Link>
                ) : (
                  row.shipping.name ||
                  row.shipping.firstName + ' ' + row.shipping.lastName
                )}
              </TableCell>
              <TableCell>
                {row.status.toTitleCase()}
                {row.status === 'shipped' &&
                  row.trackingInfo &&
                  row.trackingInfo[0].status && (
                    <span> ({row.trackingInfo[0].status.statusByLocale})</span>
                  )}
              </TableCell>
              <TableCell>
                {new Date(row._system.created).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                {row.trackingInfo && row.trackingInfo[0].trackingNum}
              </TableCell>
              <TableCell align="right">
                ${(row.payment.shippingCost / 100).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {row.shipping.cost ? '$' + row.shipping.cost.toFixed(2) : 'N/A'}
              </TableCell>
              <TableCell align="right">
                ${(row.payment.tax / 100).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                ${(row.payment.amount / 100).toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={Number(qs.limit) || 0}
              page={Number(qs.page) || 0}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </React.Fragment>
  );
}
