/** @format */

import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

export default function SimpleDialog(props) {
  const {onClose, content, title, open, maxWidth = 'lg'} = props;

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth={maxWidth}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      {content}
    </Dialog>
  );
}
