/** @format */

import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import MDEditor from '@uiw/react-md-editor';
import {TextField, Button, Paper} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 10,
    right: 10,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Orders() {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [post, setPost] = useState('');
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState('');
  const [category, setCategory] = useState('');
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const handleChangePage = (e, page) => {
    setPage(page + 1);
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(Number(e.target.value));
  };

  const toggleDialog = () => {
    setShow(!show);
  };

  const handleChangePost = data => {
    console.log(data);
    if (title && data) {
      setValid(true);
    } else {
      setValid(false);
    }
    setPost(data);
  };

  const savePost = () => {
    let body = {
      title,
      post,
      category,
      tags: tags.split(','),
    };
    let url = `/api/blog/post`;
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        //open dialog
        setShow(false);
        console.log(resp);
      })
      .catch(e => {});
  };

  const editPost = post => () => {
    console.log(post);
  };

  const deletePost = id => () => {
    console.log(id);
    let url = `/api/blog/post/${id}`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        //open dialog
        console.log(resp);
      })
      .catch(e => {});
  };

  useEffect(() => {
    let url = `/api/blog/page/${page}?numPerPage=${rowsPerPage}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        //open dialog
        setOrders(resp.posts);
        setCount(resp.totalPosts);
      })
      .catch(e => {});
  }, [page, rowsPerPage]);
  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={toggleDialog}>
        <AddIcon />
      </Fab>
      {show && (
        <div className="container">
          <Paper className="container" style={{textAlign: 'left', padding: 20}}>
            <TextField
              id="standard-basic"
              style={{width: '50%'}}
              label="Title"
              value={title}
              onChange={e => {
                setTitle(e.target.value);
              }}
            />
            <br />
            <br />
            <MDEditor value={post} onChange={handleChangePost} />
            <br />
            <br />
            <TextField
              id="standard-basic"
              style={{width: '50%'}}
              label="Tags Comma Seperated"
              value={tags}
              onChange={e => {
                setTags(e.target.value);
              }}
            />
            <TextField
              id="standard-basic"
              style={{width: '50%'}}
              label="Category"
              value={category}
              onChange={e => {
                setCategory(e.target.value);
              }}
            />
            <br />
            <br />
            <div style={{textAlign: 'right'}}>
              <Button variant="contained" onClick={toggleDialog}>
                Cancel
              </Button>{' '}
              <Button
                variant="contained"
                color="primary"
                onClick={savePost}
                disabled={!valid}>
                Save
              </Button>
            </div>
            <br />
          </Paper>
          <br />
          <br />
        </div>
      )}
      <Table size="small">
        <TableHead>
          <TableRow></TableRow>
        </TableHead>
        <TableBody style={{textAlign: 'left'}}>
          {orders.map((row, i) => (
            <TableRow key={i}>
              <td>
                <Typography variant="h4" gutterBottom>
                  {row.title}
                  <IconButton
                    aria-label="edit"
                    onClick={editPost(row)}
                    color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={deletePost(row.id)}
                    color="primary">
                    <DeleteIcon />
                  </IconButton>
                </Typography>
                <Divider />
                {new Date(row.date).toLocaleDateString()}
                <div dangerouslySetInnerHTML={{__html: row.content}} />
                <div>
                  <Chip
                    label={row.category}
                    style={{margin: 10}}
                    color="primary"
                  />
                  {row.tags.map((tag, i) => {
                    return (
                      <Chip
                        key={i}
                        label={tag}
                        style={{margin: 10}}
                        color="primary"
                        variant="outlined"
                      />
                    );
                  })}
                </div>
              </td>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              colSpan={6}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}
