/** @format */

import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Address from './address';

export default function AddOrder({
  openItem,
  handleItemClose,
  orderInfo,
  addAlert,
}) {
  const [paymentInfo, setPaymentInfo] = useState({
    total: {value: 0},
    type: {value: 'invoice'},
    orderId: {value: ''},
  });
  const [shippingAddress, SetShippingAddress] = useState({});
  const validated = true; // The code I deleted boils down to this, should be fixed

  const submit = () => {
    let url = `/api/sales/`;
    let shippingInfo = {};
    Object.keys(shippingAddress).forEach(k => {
      if (k === 'serviceCode') {
        shippingInfo[k] = shippingAddress[k].code;
      } else {
        shippingInfo[k] = shippingAddress[k].value;
      }
    });

    let payments = {};
    Object.keys(paymentInfo).forEach(k => {
      if (paymentInfo[k].code) {
        payments[k] = paymentInfo[k].code;
      } else {
        payments[k] = paymentInfo[k].value;
      }
    });

    payments.shippingCost = orderInfo.shippingCost;
    payments.total =
      Number(orderInfo.price) * orderInfo.qty + orderInfo.shippingCost;

    const items = [
      {
        itemId: '6697e08fca89cda62f4c8298',
        name:
          'Print on Demand - ' +
          orderInfo.options.color +
          ' - ' +
          orderInfo.options.material,
        ...orderInfo,
      },
    ];

    const body = {
      items,
      shippingAddress: shippingInfo,
      paymentInfo: payments,
      cartLines: true,
    };

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(resp => {
        handleItemClose();
      })
      .catch(e => {
        addAlert(e.message, 'error');
      });
  };

  const onClose = () => {
    handleItemClose();
  };

  return (
    <Dialog
      open={openItem}
      maxWidth={'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        Create print on Demand Order
      </DialogTitle>
      <DialogContent>
        <Address updateFunc={SetShippingAddress} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={submit}
          disabled={validated !== true}
          variant="outlined"
          color="primary">
          Add Order
        </Button>
      </DialogActions>
    </Dialog>
  );
}
