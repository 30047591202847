/** @format */

import React, {useState, useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import UserOrders from './orders';

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  button: {
    // margin: theme.spacing(1),
  },
  preview: {
    maxHeight: 75,
  },
  label: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    zIndex: 1000,
    opacity: 0,
    visibility: 'hidden',
  },
  textField: {
    marginTop: 0,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

export default function Orders(props) {
  const {match} = props;
  const id = match.params.id;
  const [user, setUser] = useState({});
  const [fetching, setFetching] = useState(true);
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  useEffect(() => {
    let url = '/api/user/' + id;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        // open dialog
        setUser(resp);
        setFetching(false);
      })
      .catch(e => {});
  }, [id]);

  return (
    <>
      {fetching === true ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={5} lg={4}>
            <Paper className={fixedHeightPaper}>
              <div style={{padding: 40, textAlign: 'left'}}>
                <Avatar
                  alt={user.displayName}
                  src={user.avatar}
                  className={classes.large}
                />
                <br />
                <TextField
                  id="standard-name"
                  label="Name"
                  className={classes.textField}
                  value={user.displayName || user.fname + ' ' + user.lname}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="standard-name"
                  label="Registered"
                  className={classes.textField}
                  value={new Date(user.ts).toLocaleString()}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={false} md={false} lg={4}></Grid>
          <Grid item xs={12} md={6} lg={4}></Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <UserOrders userId={id} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
