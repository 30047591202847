/** @format */

import React, {useEffect, useState, useRef} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

export default function AddHolliday({
  openItem,
  handleItemClose,
  addAlert,
  selected = {},
}) {
  const [note, setNote] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [image, setImage] = useState('');
  const validated = true; // The code I deleted boils down to this, should be fixed
  const classes = useStyles();

  const submit = async () => {
    let url = `/api/holliday/`;
    if (selected.id) {
      url = url + selected.id;
    }
    let startTime = new Date(start);
    startTime.setHours(0, 0, 0, 0);
    let endTime = new Date(end);
    endTime.setHours(0, 0, 0, 0);

    const body = {
      image,
      note,
      start: startTime.getTime(),
      end: endTime.getTime(),
    };

    fetch(url, {
      method: selected.id ? 'PUT' : 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(resp => {
        handleItemClose();
      })
      .catch(e => {
        addAlert(e.message, 'error');
      });
  };

  useEffect(() => {
    if (selected.id) {
      console.log(formatDate(selected.start));
      setNote(selected.note);
      setStart(formatDate(selected.start));
      setEnd(formatDate(selected.end));
      setImage(selected.image);
    }
  }, [selected]);

  const onClose = () => {
    handleItemClose();
  };

  const onChange = e => {
    let files = e.target.files;
    let reader = new FileReader();
    reader.onload = r => {
      setImage(r.target.result.split(',')[1]);
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <Dialog
      open={openItem}
      maxWidth={'sm'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Add Notice</DialogTitle>
      <DialogContent>
        <TextField
          id="image"
          label="Image"
          type="file"
          className={classes.textField}
          autoComplete="current-password"
          onChange={onChange}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {image && (
                  <Avatar alt="image" src={'data:image/png;base64,' + image} />
                )}
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <TextField
          id="note"
          label="Note"
          type="text"
          multiline={true}
          rows={2}
          className={classes.textField}
          value={note}
          fullWidth
          onChange={e => setNote(e.target.value)}
          autoComplete="current-password"
          variant="outlined"
        />
        <TextField
          id="start"
          label="Start"
          className={classes.textField}
          type="date"
          value={start}
          fullWidth
          autoComplete="current-password"
          onChange={e => {
            console.log(e.target.value);
            setStart(e.target.value);
          }}
          variant="outlined"
        />
        <TextField
          id="end"
          label="End"
          className={classes.textField}
          type="date"
          value={end}
          fullWidth
          onChange={e => setEnd(e.target.value)}
          autoComplete="current-password"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={submit}
          disabled={validated !== true}
          variant="outlined"
          color="primary">
          {selected && selected.id ? 'Update Notice' : 'Add Notice'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const formatDate = date => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
};
